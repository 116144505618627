import SuperExpressive from "super-expressive";
import {
  CompoundPath,
  Font,
  FontGlyph,
  FontSymbolRetriever,
  GlyphAlternateName,
  isArray,
  isString,
  RichText,
  RichTextGlyph,
} from "..";
import type {
  GlyphAlternateInfo,
  GlyphAlternateRange,
  AlternateGlyphReplacements,
  StringGlyphRun,
} from "..";
import opentype from "opentype.js";
import { logManager } from "../../log-manager";

const OPENTYPE_RENDER_OPTIONS = {
  kerning: true,
  features: { liga: false, rlig: false },
};

/**
 * Patch opentype's forEachGlyph to accept RichTextGlyph references as well.
 */
declare module "opentype.js" {
  interface Font {
    // Missing in @types definition
    stringToGlyphs(s: string, options?: RenderOptions): Glyph[];
    position: any;
  }
  interface GSUBTable {
    features: Array<{
      tag: string;
      feature: {
        lookupListIndexes: number[];
      };
    }>;
    lookups: Array<{
      subtables: Array<{
        coverage:
          | {
              format: 1;
              glyphs: number[];
            }
          | {
              format: 2;
              ranges: Array<{ start: number; end: number; index: number }>;
            };
        alternateSets: number[][];
      }>;
    }>;
  }
}
/**
 * Vendored version of opentype.js' Font.forEachGlyph, to support runs including
 * RichTextGlyph, while still supporting kerning. Original implementation:
 * https://github.com/opentypejs/opentype.js/blob/52d167f5570c98d4614e241712fd899b3d84073a/src/font.mjs#L378-L413
 */
function otForEachGlyph(
  text: string | StringGlyphRun,
  font: opentype.Font,
  callback: (
    glyph: opentype.Glyph,
    x: number,
    y: number,
    fontSize: number,
    options?: opentype.RenderOptions
  ) => void
) {
  let x = 0;
  const y = 0;
  const fontSize = 1;
  const options = Object.assign({}, font.defaultRenderOptions, OPENTYPE_RENDER_OPTIONS);
  const fontScale = (1 / font.unitsPerEm) * fontSize;

  let warning: string | undefined;

  let glyphs = [];
  if (isString(text)) {
    glyphs = font.stringToGlyphs(text, options);
  } else {
    for (const item of text) {
      if (item instanceof RichTextGlyph) {
        // @ts-ignore - working around bug with out-of-bound index
        // https://github.com/opentypejs/opentype.js/pull/755
        const glyph = font.glyphs.glyphs[item.index];
        if (glyph) {
          glyphs.push(glyph);
        } else if (!warning) {
          warning = `Glyph index ${item.index} not found in font.`;
        }
      } else {
        glyphs.push(...font.stringToGlyphs(item, options));
      }
    }
  }

  if (warning) {
    logManager.consoleWarnGlobal(warning);
  }

  let kerningLookups;
  if (options.kerning) {
    const script = options.script || font.position.getDefaultScriptName();
    kerningLookups = font.position.getKerningTables(script, options.language);
  }
  for (let i = 0; i < glyphs.length; i += 1) {
    const glyph = glyphs[i];
    callback.call(font, glyph, x, y, fontSize, options);
    if (glyph.advanceWidth) {
      x += glyph.advanceWidth * fontScale;
    }

    if (options.kerning && i < glyphs.length - 1) {
      const kerningValue = kerningLookups
        ? font.position.getKerningValue(kerningLookups, glyph.index, glyphs[i + 1].index)
        : font.getKerningValue(glyph, glyphs[i + 1]);
      x += kerningValue * fontScale;
    }

    if (options.letterSpacing) {
      x += options.letterSpacing * fontSize;
    } else if (options.tracking) {
      x += (options.tracking / 1000) * fontSize;
    }
  }
  return x;
}

interface OpenTypeFontOptions {
  /**
   * Type 1 single-line fonts are a convention that stores an open-stroke only
   * font in a TTF or OTF (formats designed for filled shapes). We can't know
   * just by looking at font if it's single-line or not, so we need rely on a
   * flag.
   */
  isSingleLine: boolean;
  glyphAlternates?: GlyphAlternateInfo;
}

export class OpenTypeFont extends Font {
  _family: string;
  _variant: string;

  ascenderHeight: number;
  descenderHeight: number;

  isSingleLine: boolean = false;
  private glyphAlternates: GlyphAlternateInfo = {};

  private unitsPerEm: number;
  private font: opentype.Font;

  constructor(
    openTypeFont: opentype.Font,
    symbolRetriever: FontSymbolRetriever,
    options: OpenTypeFontOptions
  ) {
    super(symbolRetriever);

    this.font = openTypeFont;
    this.unitsPerEm = openTypeFont.unitsPerEm;

    // Save relative to 1em
    this.ascenderHeight = openTypeFont.ascender / this.unitsPerEm;
    this.descenderHeight = openTypeFont.descender / this.unitsPerEm;
    this.isSingleLine = options.isSingleLine;
    if (options.glyphAlternates) {
      this.glyphAlternates = options.glyphAlternates;
    }

    this._family = openTypeFont.getEnglishName("fontFamily");
    this._variant = openTypeFont.getEnglishName("fontSubfamily");
  }

  _glyphsFromTextString(textString: string | StringGlyphRun) {
    const metricsScale = 1 / this.unitsPerEm;

    const font = this.font;
    const isSingleLine = this.isSingleLine;
    const outputGlyphs: FontGlyph[] = [];

    let prevGlyph: FontGlyph | undefined;

    let warning: string | undefined;

    // This is how opentype's Font.getPaths uses forEachGlyph, which handles
    // kerning, so we don't have to do that in the modifier
    otForEachGlyph(textString, this.font, (glyph, x, _y, fontSize, options) => {
      const { index, name } = glyph;

      let advanceWidth = metricsScale;
      if (glyph.advanceWidth) {
        advanceWidth = glyph.advanceWidth * metricsScale;
      }
      const glyphPath = glyph.getPath(0, 0, fontSize, options, font);

      let geometry = CompoundPath.fromOpenTypePath(glyphPath);
      if (isSingleLine) {
        for (const path of geometry.paths) {
          path.closed = false;
          const { anchors } = path;
          // Remove the first anchor and append it to the end of the path, but
          // only if it makes a visible difference.
          const firstAnchor = anchors.shift();
          if (firstAnchor) {
            const lastAnchor = anchors[anchors.length - 1];
            if (!firstAnchor.position.equals(lastAnchor.position)) {
              anchors.push(firstAnchor);
            }
          }
        }
      } else {
        // Glyphs are designed for the "winding" fill rule, so we convert them to
        // "even-odd" by unioning.
        let evenoddPath = CompoundPath.booleanUnion([geometry], "winding");

        // Some "single line" TTF fonts have zero area and will disappear when
        // unioned.
        if (evenoddPath.paths.length >= 1) {
          geometry = evenoddPath;
        }
      }

      if (index === 0 && !warning) {
        // Also shows when rendering RichTextGlyph(0), the .notdef tofu glyph.
        warning = `Text is not supported by the selected font. Try searching in the font picker for the language of your text, for example “Arabic” or “Japanese”.`;
      }

      const currentGlyph = new FontGlyph(name || "", x, advanceWidth, advanceWidth, geometry);

      if (prevGlyph) {
        // Adjust the previous glyph's advanceWidth to account for adjustments
        // like letter spacing and kerning.
        prevGlyph.advanceX = currentGlyph.x - prevGlyph.x;
      }
      prevGlyph = currentGlyph;

      outputGlyphs.push(currentGlyph);
    });

    if (warning) {
      logManager.consoleWarnGlobal(warning);
    }

    return outputGlyphs;
  }

  replacePatternsWithGlyphAlternates(
    text: string | RichText,
    replacements: AlternateGlyphReplacements
  ): RichText {
    text = new RichText(text);

    if (!replacements.wordConnect && !replacements.wordStart && !replacements.wordEnd) {
      return text;
    }

    const glyphAlternateValues: GlyphAlternateRange[][] = Object.values(this.glyphAlternates || {});

    // We can also pass custom ranges directly in the replacements object.
    if (isArray(replacements.wordStart)) {
      glyphAlternateValues.push(replacements.wordStart);
    }
    if (isArray(replacements.wordConnect)) {
      glyphAlternateValues.push(replacements.wordConnect);
    }
    if (isArray(replacements.wordEnd)) {
      glyphAlternateValues.push(replacements.wordEnd);
    }

    if (glyphAlternateValues.length === 0) {
      warnNotFound(this._family);
      return text;
    }

    // Names for the named capture groups.
    const WordConnect = "WordConnect";
    const WordStart = "WordStart";
    const WordEnd = "WordEnd";

    const seSubexpressions = [];
    if (replacements.wordConnect) {
      // Capture the "connecting" space, so that it can be replaced with the
      // character. Then lookahead so that the following non-space character is
      // not consumed.

      // prettier-ignore
      seSubexpressions.push(
        SuperExpressive()
          .namedCapture(WordConnect)
            .nonWhitespaceChar
            .char(" ")
          .end()
          .assertAhead
            .anythingButChars(" ")
          .end()
      );
    }
    if (replacements.wordStart) {
      // Use a non-capturing group for the preceding space, so that a previous
      // WordConnect will block a match here, but a previous WordEnd will not.
      // This means that a match at the start of a line will be the one
      // character, while a match after a space will include the space.

      // prettier-ignore
      seSubexpressions.push(
        SuperExpressive()
          .anyOf.char(" ").startOfInput.end()
          .namedCapture(WordStart)
            .nonWhitespaceChar
          .end()
      );
    }
    if (replacements.wordEnd) {
      // Use a lookahead for the following space, so that the next WordStart can
      // still match the space.

      // prettier-ignore
      seSubexpressions.push(
        SuperExpressive()
          .namedCapture(WordEnd)
            .nonWhitespaceChar
          .end()
          .assertAhead
            .anyOf.char(" ").endOfInput.end()
          .end()
      );
    }

    // Open expression with anyOf to build with the subexpressions.
    let seMatches = SuperExpressive().allowMultipleMatches.lineByLine.anyOf;
    for (const seSubexpression of seSubexpressions) {
      seMatches = seMatches.subexpression(seSubexpression, {
        // Needed for startOfInput and endOfInput in subexpressions.
        ignoreStartAndEnd: false,
      });
    }
    seMatches = seMatches.end();

    const asString = text.toString();
    const matches = asString.matchAll(seMatches.toRegex());

    // So indexes are consistent when replacing from the end of the text.
    const matchesReversed = Array.from(matches).reverse();

    // Do not warn when no matches were found.
    if (matchesReversed.length === 0) {
      return text;
    }

    for (const match of matchesReversed) {
      if (!match || match.index === undefined || !match.groups) continue;

      if (match.groups[WordConnect] && replacements.wordConnect) {
        text = this._replaceMatchWithGlyphAlternate(
          text,
          match,
          replacements.wordConnect,
          match.index,
          0,
          match.index + 2 // Eats the space between words.
        );
      }
      if (match.groups[WordStart] && replacements.wordStart) {
        // This match will be either "a" or " a" depending on the position.
        const charIndexWithinMatch = match[0].length - 1;
        text = this._replaceMatchWithGlyphAlternate(
          text,
          match,
          replacements.wordStart,
          match.index + charIndexWithinMatch, // Ignore leading space, if any.
          charIndexWithinMatch,
          match.index + charIndexWithinMatch + 1
        );
      }
      if (match.groups[WordEnd] && replacements.wordEnd) {
        text = this._replaceMatchWithGlyphAlternate(
          text,
          match,
          replacements.wordEnd,
          match.index,
          0,
          match.index + 1
        );
      }
    }

    return text;
  }
  private _replaceMatchWithGlyphAlternate(
    text: RichText,
    match: RegExpMatchArray,
    replacement: GlyphAlternateName | GlyphAlternateRange[],
    startIndex: number,
    charIndexWithinMatch: number,
    endIndex: number
  ): RichText {
    if (replacement === "skip") return text;

    const matchCodePoint = match[0].codePointAt(charIndexWithinMatch);
    if (matchCodePoint === undefined) return text;

    const ranges = isArray(replacement) ? replacement : this.glyphAlternates[replacement];
    if (!ranges) {
      warnNotFound(this._family, replacement);
      return text;
    }

    const matchRange = findMatchRange(ranges, matchCodePoint);
    if (!matchRange) {
      warnNotFound(this._family, replacement, match[0]);
      return text;
    }

    const glyphIndex = this._findGlyphReplacement(matchRange, matchCodePoint);
    if (glyphIndex === undefined) {
      warnNotFound(this._family, replacement, match[0]);
      return text;
    }

    return new RichText(
      text.slice(0, startIndex),
      new RichTextGlyph(glyphIndex),
      text.slice(endIndex)
    );
  }
  /** Digs into the OpenType font tables. */
  private _findGlyphReplacement(range: GlyphAlternateRange, codePoint: number) {
    if (range.type === "glyphIndices") {
      const { rangeStart, glyphIndices } = range;
      return glyphIndices[codePoint - rangeStart];
    }
    if (range.type === "alternateIndex") {
      const { alternateIndex } = range;

      const char = String.fromCodePoint(codePoint);
      const charIndex = this.font.charToGlyphIndex(char);

      const gsub = this.font.tables?.gsub as unknown as opentype.GSUBTable;
      if (!gsub) return;

      let aalt = gsub.features.find((feature: any) => feature.tag === "aalt");
      if (!aalt) return;

      const { lookupListIndexes } = aalt.feature;
      const lookups = gsub.lookups.filter((lookup, i) => lookupListIndexes.indexOf(i) > -1);

      let charAlternates: number[] | undefined;
      for (const lookup of lookups) {
        for (const subtable of lookup.subtables) {
          const { coverage, alternateSets } = subtable;
          if (!alternateSets) continue;
          if (coverage.format === 1) {
            const charAltIndex = coverage.glyphs.indexOf(charIndex);
            charAlternates = alternateSets[charAltIndex];
            break;
          } else if (coverage.format === 2) {
            const range = coverage.ranges.find(
              (range) => range.start <= charIndex && charIndex <= range.end
            );
            if (!range) continue;
            const charAltIndex = range.index + charIndex - range.start;
            charAlternates = alternateSets[charAltIndex];
            break;
          }
        }
        if (charAlternates) break;
      }

      return charAlternates?.[alternateIndex];
    }
  }
}

function findMatchRange(variants: Array<GlyphAlternateRange>, codePoint: number) {
  return variants.find((range) => codePoint >= range.rangeStart && codePoint <= range.rangeEnd);
}

function warnNotFound(
  fontName?: string,
  glyphAlternateName?: string | GlyphAlternateRange[],
  match?: string
) {
  const font = fontName ? `“${fontName}” ` : "";
  const readableNames = {
    leftTail: "left tail",
    rightTail: "right tail",
    rightHeart: "heart",
  };
  const matchTrim = match?.trim();
  const aName = matchTrim ? "a " : "";
  let name = "";
  let tryThis = "";
  if (isString(glyphAlternateName)) {
    if (readableNames.hasOwnProperty(glyphAlternateName)) {
      //@ts-ignore
      name = `“${readableNames[glyphAlternateName]}” `;
    } else {
      name = `“${glyphAlternateName}” `;
      tryThis = "Check the variation name";
    }
  }
  isString(glyphAlternateName) && readableNames.hasOwnProperty(glyphAlternateName)
    ? //@ts-ignore
      `“${readableNames[glyphAlternateName]}” `
    : "";
  const forMatch = matchTrim ? `variation for the character “${matchTrim}”` : "variations";
  if (!tryThis) {
    tryThis =
      matchTrim && matchTrim.toLowerCase() !== matchTrim
        ? `Try using lowercase “${matchTrim.toLowerCase()}”`
        : "Try another font";
  }
  logManager.consoleWarnGlobal(
    `The font ${font}does not have ${aName}${name}${forMatch}. ${tryThis}.`
  );
}
