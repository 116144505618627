import {
  AffineMatrix,
  Anchor,
  Color,
  CompoundPath,
  Fill,
  Graphic,
  Group,
  Path,
  Stroke,
  Unit,
  Vec,
  clamp,
  isValidUnit,
  scaleFactorForUnitConversion,
} from "..";

export interface ImportSVGOptions {
  units: Unit;
  sourceUnits?: Unit;
  origin?: "top-left" | "middle";
  fitSize?: Vec;
}

/**
 * Given an SVG string returns a Graphic or undefined on parse failure.
 *
 * This implementation is very heavily dependent on the browser. It makes a
 * dummy iframe, puts the svg in there, and then uses browser APIs like
 * window.getComputedStyle. This way, we leverage the browser to reconcile CSS
 * rules. The disadvantage is there may be cross-browser issues to watch out
 * for.
 *
 * @internal
 */
export const graphicFromSVGString = (svgString: string, options: ImportSVGOptions) => {
  const iframe = document.createElement("iframe");

  // We might not need to sandbox the iframe. It seems like since we're removing
  // the iframe on the same event loop, most XSS javascript in the svgString
  // will not run. But I'd rather be safe by setting the iframe sandbox to
  // disallow anything except same-origin reading.
  iframe.setAttribute("sandbox", "allow-same-origin");

  // The iframe must be inserted into the document in order to read its
  // contentDocument. And it needs to be in the body for Firefox to process its
  // CSS.
  document.body.appendChild(iframe);

  const doc = iframe!.contentDocument!;

  doc.body.innerHTML = svgString;
  const topElem = doc.querySelector("svg");
  if (!topElem) {
    return undefined;
  }

  // Check for Adobe Illustrator
  const isIllustrator = Array.from(doc.body.childNodes).some((childNode) => {
    return (
      childNode.nodeType === window.Node.COMMENT_NODE &&
      (childNode as Comment).data.indexOf("Generator: Adobe Illustrator") !== -1
    );
  });

  // Adobe Illustrator copies SVG data as 72ppi but with "px" units. We need
  // to override the source units, to import at 72 instead of 96ppi.
  if (isIllustrator) {
    options = { ...options, sourceUnits: "pt" };
  }

  const graphic = graphicFromSVGElement(topElem, topElem, options);
  iframe.remove();
  return graphic;
};

/**
 * Takes two anchors and using their positions mutates anchor1's handleOut and
 * anchor2's handleIn so that there's a circular arc going from anchor1 to
 * anchor2. If horiziontal is true, the arc leaves anchor1 going in a horizontal
 * direction. It's vertical otherwise.
 *
 * This might be a useful geometry routine to expose.
 *
 * @internal
 */
const makeCircularArc = (anchor1: Anchor, anchor2: Anchor, horizontal: boolean) => {
  const c = 0.551915024494;
  const x1 = anchor1.position.x;
  const y1 = anchor1.position.y;
  const x2 = anchor2.position.x;
  const y2 = anchor2.position.y;
  if (horizontal) {
    anchor1.handleOut = new Vec((x2 - x1) * c, 0);
    anchor2.handleIn = new Vec(0, (y1 - y2) * c);
  } else {
    anchor1.handleOut = new Vec(0, (y2 - y1) * c);
    anchor2.handleIn = new Vec((x1 - x2) * c, 0);
  }
};

const stringForAttr = <T>(svgNode: SVGElement, name: string, defaultValue: T): string | T => {
  const attr = svgNode.getAttribute(name);
  if (attr !== null) return attr;
  return defaultValue;
};
const numberForAttr = <T>(svgNode: SVGElement, name: string, defaultValue: T): number | T => {
  const attr = svgNode.getAttribute(name);
  if (attr !== null) return parseFloat(attr);
  return defaultValue;
};

const numberAndUnitFromString = (s: string) => {
  const numberString = s.match(/[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?/)?.[0];
  const number = numberString ? parseFloat(numberString) : undefined;
  const unit = s.match(/[^\d.]+$/)?.[0];
  return { number, unit };
};

const transformGraphicForViewbox = (
  graphic: Graphic,
  svgNode: SVGElement,
  options: ImportSVGOptions
) => {
  const viewboxAttribute = stringForAttr(svgNode, "viewBox", undefined);
  if (viewboxAttribute !== undefined) {
    const viewboxNumbers = viewboxAttribute.split(/[\s,]+/).map((s) => parseFloat(s));
    const [viewboxX, viewboxY, viewboxWidth, viewboxHeight] = viewboxNumbers;
    if (options.origin === "middle") {
      // Translate so origin is at middle of viewbox.
      graphic.transform({
        position: new Vec(-viewboxX - viewboxWidth * 0.5, -viewboxY - viewboxHeight * 0.5),
      });
    } else {
      // Translate so origin is at top-left of viewbox.
      graphic.transform({ position: new Vec(-viewboxX, -viewboxY) });
    }

    // Default is points, i.e. 72 dots per inch.
    let scaleFactor = scaleFactorForUnitConversion("pt", options.units);
    if (options.fitSize) {
      // Scale so fitSize is the same size as the viewbox.
      const fitWidth = options.fitSize.x;
      const fitHeight = options.fitSize.y;
      const scaleX = fitWidth / viewboxWidth;
      const scaleY = fitHeight / viewboxHeight;
      scaleFactor = Math.min(scaleX, scaleY);
    } else if (options.sourceUnits) {
      scaleFactor = scaleFactorForUnitConversion(options.sourceUnits, options.units);
    } else {
      const widthAttribute = stringForAttr(svgNode, "width", undefined);
      if (widthAttribute !== undefined) {
        // If width has a unit, we'll attempt to determine a scale factor.
        let { number: width, unit: widthUnit } = numberAndUnitFromString(widthAttribute);
        if (!widthUnit) widthUnit = "pt";
        if (width !== undefined && isValidUnit(widthUnit)) {
          const widthInProjectUnits =
            width * scaleFactorForUnitConversion(widthUnit, options.units);
          scaleFactor = widthInProjectUnits / viewboxWidth;
        }
      }
    }
    graphic.transform({ scale: scaleFactor });
    graphic.scaleStroke(scaleFactor);
  }
};

const tagNamesWithStyle: { [tagName: string]: boolean } = {
  // https://www.w3.org/TR/SVG11/shapes.html
  circle: true,
  ellipse: true,
  line: true,
  path: true,
  polygon: true,
  polyline: true,
  rect: true,
  // https://www.w3.org/TR/SVG11/text.html
  text: true,
  textPath: true,
  tspan: true,
};

const graphicFromSVGElement = (
  topElem: SVGElement,
  svgElem: SVGElement,
  options: ImportSVGOptions
): Graphic | undefined => {
  // The general strategy is to convert the svgNode to geometry based on its
  // tagName (ellipse, rect, path, etc) and that tag's specialized attributes
  // (e.g. circle's cx, cy, r), then apply all the generic attributes and css style
  // (transform, stroke, etc) to that geometry.

  // TODO: We assume all numeric units are in pixels (this is typical). But the
  // spec says they can also be in css units and percentages.

  let result: Graphic | undefined;
  const tagName = svgElem.tagName;

  function groupFromChildren(children: HTMLCollection) {
    const items: Graphic[] = [];
    for (const childElem of Array.from(svgElem.children)) {
      const childGeom = graphicFromSVGElement(topElem, childElem as SVGElement, options);
      if (childGeom) items.push(childGeom);
    }
    if (items.length) {
      return new Group(items);
    }
    return;
  }

  // Only render <symbol> as a group if it's referenced from a <use> tag.
  const isSymbolReferencedFromUse = tagName === "symbol" && topElem === svgElem;

  if (tagName === "svg" || tagName === "g" || tagName === "switch" || isSymbolReferencedFromUse) {
    result = groupFromChildren(svgElem.children);
    if (result) {
      if (tagName === "svg") {
        transformGraphicForViewbox(result, svgElem, options);
      } else if (tagName === "symbol") {
        transformGraphicForViewbox(result, svgElem, { units: "pt" });
      }
    }
  } else if (tagName === "path") {
    const d = stringForAttr(svgElem, "d", "");
    result = CompoundPath.fromSVGPathString(d);
  } else if (tagName === "polygon") {
    const d = "M" + stringForAttr(svgElem, "points", "") + "z";
    result = CompoundPath.fromSVGPathString(d);
  } else if (tagName === "polyline") {
    const d = "M" + stringForAttr(svgElem, "points", "");
    result = CompoundPath.fromSVGPathString(d);
  } else if (tagName === "circle") {
    const cx = numberForAttr(svgElem, "cx", 0);
    const cy = numberForAttr(svgElem, "cy", 0);
    const r = numberForAttr(svgElem, "r", 0);
    if (r > 0) {
      const anchors = [
        new Anchor(new Vec(cx + r, cy)),
        new Anchor(new Vec(cx, cy + r)),
        new Anchor(new Vec(cx - r, cy)),
        new Anchor(new Vec(cx, cy - r)),
      ];
      makeCircularArc(anchors[0], anchors[1], false);
      makeCircularArc(anchors[1], anchors[2], true);
      makeCircularArc(anchors[2], anchors[3], false);
      makeCircularArc(anchors[3], anchors[0], true);
      result = new Path(anchors, true);
    }
  } else if (tagName === "ellipse") {
    const cx = numberForAttr(svgElem, "cx", 0);
    const cy = numberForAttr(svgElem, "cy", 0);
    const rx = numberForAttr(svgElem, "rx", 0);
    const ry = numberForAttr(svgElem, "ry", 0);
    if (rx > 0 && ry > 0) {
      const anchors = [
        new Anchor(new Vec(cx + rx, cy)),
        new Anchor(new Vec(cx, cy + ry)),
        new Anchor(new Vec(cx - rx, cy)),
        new Anchor(new Vec(cx, cy - ry)),
      ];
      makeCircularArc(anchors[0], anchors[1], false);
      makeCircularArc(anchors[1], anchors[2], true);
      makeCircularArc(anchors[2], anchors[3], false);
      makeCircularArc(anchors[3], anchors[0], true);
      result = new Path(anchors, true);
    }
  } else if (tagName === "rect") {
    const x = numberForAttr(svgElem, "x", 0);
    const y = numberForAttr(svgElem, "y", 0);
    const width = numberForAttr(svgElem, "width", 0);
    const height = numberForAttr(svgElem, "height", 0);
    let rx = numberForAttr(svgElem, "rx", undefined);
    let ry = numberForAttr(svgElem, "ry", undefined);
    if (rx !== undefined && ry === undefined) ry = rx;
    if (ry !== undefined && rx === undefined) rx = ry;
    if (rx === undefined || ry === undefined) {
      const anchors = [
        new Anchor(new Vec(x, y)),
        new Anchor(new Vec(x + width, y)),
        new Anchor(new Vec(x + width, y + height)),
        new Anchor(new Vec(x, y + height)),
      ];
      result = new Path(anchors, true);
    } else {
      rx = clamp(rx, 0, width / 2);
      ry = clamp(ry, 0, height / 2);
      const anchors = [
        new Anchor(new Vec(x + rx, y)),
        new Anchor(new Vec(x + width - rx, y)),
        new Anchor(new Vec(x + width, y + ry)),
        new Anchor(new Vec(x + width, y + height - ry)),
        new Anchor(new Vec(x + width - rx, y + height)),
        new Anchor(new Vec(x + rx, y + height)),
        new Anchor(new Vec(x, y + height - ry)),
        new Anchor(new Vec(x, y + ry)),
      ];
      makeCircularArc(anchors[7], anchors[0], false);
      makeCircularArc(anchors[1], anchors[2], true);
      makeCircularArc(anchors[3], anchors[4], false);
      makeCircularArc(anchors[5], anchors[6], true);
      result = new Path(anchors, true);
    }
  } else if (tagName === "line") {
    const x1 = numberForAttr(svgElem, "x1", 0);
    const y1 = numberForAttr(svgElem, "y1", 0);
    const x2 = numberForAttr(svgElem, "x2", 0);
    const y2 = numberForAttr(svgElem, "y2", 0);
    const anchor1 = new Anchor(new Vec(x1, y1));
    const anchor2 = new Anchor(new Vec(x2, y2));
    result = new Path([anchor1, anchor2]);
  } else if (tagName === "use") {
    const href = svgElem.getAttribute("href") ?? svgElem.getAttribute("xlink:href");
    if (href !== null) {
      // The ref element will be a <symbol> or the <defs> element.
      const refElem = topElem.querySelector(href);
      result = graphicFromSVGElement(refElem as SVGElement, refElem as SVGElement, options);
      if (result) {
        const x = numberForAttr(svgElem, "x", 0);
        const y = numberForAttr(svgElem, "y", 0);
        if (x !== 0 || y !== 0) {
          result.transform({ position: new Vec(x, y) });
        }
      }
    }
  } else if (tagName === "defs" || tagName === "symbol") {
    // Ignore on this pass. They will be found if needed by <use> element parsing.
  } else if (
    tagName === "text" ||
    tagName === "clippath" ||
    tagName === "lineargradient" ||
    tagName === "radialgradient" ||
    tagName === "image"
  ) {
    // TODO: Implement these.
    // Don't walk children.
  } else {
    // Unexpected tag, walk and render children.
    result = groupFromChildren(svgElem.children);
  }

  if (result === undefined) return undefined;

  // Now apply all the attributes (transform, stroke, etc) to result.
  // See https://www.w3.org/TR/SVG/propidx.html

  if (tagNamesWithStyle[tagName]) {
    // We'll use CSS to determine the fill and stroke style. Note that even fill
    // and stroke assigned as attributes will show up in the CSS style.
    const style = window.getComputedStyle(svgElem);

    const fillOpacity = parseFloat(style.fillOpacity);

    // Fill Rule: SVG's default is "nonzero," while Cuttle's default is
    // "evenodd." To make an imported SVG look like it does in other programs,
    // we need to union the result.
    if (tagName === "path" || tagName === "polyline") {
      if (fillOpacity > 0 && style.fill !== "none" && style.fillRule === "nonzero") {
        const evenoddResult = CompoundPath.booleanUnion([result], "winding");
        // Compound paths that only contain lines might disappear if unioned. If
        // the union results in no geometry, keep the original result.
        if (evenoddResult.paths.length > 0) {
          result = evenoddResult;
        }
      }
    }

    // Fill. Note that some SVG nodes are auto-assigned a black fill if none is
    // specified. But getComputedStyle takes care of this.
    const fill = style.fill;
    if (fill !== "none") {
      const color = Color.fromCSSString(fill);
      if (!isNaN(fillOpacity)) color.a *= fillOpacity;
      result.assignFill(new Fill(color));
    }

    // Stroke
    const stroke = style.stroke;
    if (stroke !== "none") {
      const color = Color.fromCSSString(stroke);
      const strokeOpacity = parseFloat(style.strokeOpacity);
      if (!isNaN(strokeOpacity)) color.a *= strokeOpacity;
      // Note: strokeWidth will look like "4px"
      const width = parseFloat(style.strokeWidth);
      let cap: string | undefined = style.strokeLinecap;
      if (!Stroke.isValidCap(cap)) cap = undefined;
      let join: string | undefined = style.strokeLinejoin;
      if (!Stroke.isValidJoin(join)) join = undefined;
      const miterLimit = parseFloat(style.strokeMiterlimit);
      result.assignStroke(new Stroke(color, false, width, "centered", cap, join, miterLimit));
    }
  }

  // Transform. Note that SVG uses its own transform (in an attribute) and CSS
  // transform is not relevant, so we only check the attribute.
  const transformAttribute = stringForAttr(svgElem, "transform", null);
  if (transformAttribute !== null) {
    const matrix = AffineMatrix.fromSVGTransformString(transformAttribute);
    result.affineTransform(matrix);
    // Note: We'll try to scale the stroke but we don't support non-uniform
    // stroke in our geometry model.
    const scaleFactor = Math.sqrt(Math.abs(matrix.determinant()));
    result.scaleStroke(scaleFactor);
  }

  // TODO: opacity, visibility, display
  // TODO: clip-path, gradient-transform, stop-color, offset, stroke-dasharray, stroke-dashoffset

  return result;
};
