export interface ReducerCache {
  _reducerCache?: Record<string, unknown>;
}

/**
 * @returns a function that saves its result in the object's reducer cache. If a
 * result is already in the cache, it will be returned. Otherwise `reducerFunc`
 * will be called and its result will be cached.
 *
 * Reducer functions have different strategies for merging values, so they are
 * expected to recurse into children internally.
 */
export const makeCachedReducer = <ObjType extends ReducerCache, ValueType>(
  key: string,
  reducerFunc: (obj: ObjType) => ValueType
) => {
  return (obj: ObjType) => {
    if (obj._reducerCache !== undefined) {
      // Check if the value is already cached.
      if (obj._reducerCache.hasOwnProperty(key)) {
        const cached = obj._reducerCache[key] as ValueType;
        return cached;
      }
    } else {
      // Initialize the cache.
      obj._reducerCache = {};
    }

    // Compute and store the value.
    const result = reducerFunc(obj);
    obj._reducerCache[key] = result;
    return result;
  };
};
