/**
 * 🌀 Cuttle Geometry Library 🌀
 */

export * from "./math/affine-matrix";
export * from "./math/bounding-box";
export * from "./math/constants";
export * from "./math/random";
export * from "./math/scalar-math";
export * from "./math/transform";
export * from "./math/vec";

export * from "./text/rich-text";

export * from "./geometry/geometry";

export * from "./geometry/axis";
export * from "./geometry/cubic-segment";
export * from "./geometry/line-segment";
export * from "./geometry/primitive";
export * from "./geometry/ray";

export * from "./graphic/graphic";

export * from "./graphic/anchor";
export * from "./graphic/color";
export * from "./graphic/compound-path";
export * from "./graphic/group";
export * from "./graphic/path";
export * from "./graphic/style";

export * from "./op/containment";
export * from "./op/distance-to-intersect-types";
export * from "./op/intersection-types";
export * from "./op/overlap-types";

export * from "./io/canvas";
export * from "./io/font-glyph";
export * from "./io/font";
export * from "./io/image-data";
export * from "./io/svg";
export * from "./io/to-string";
export * from "./io/units";

export * from "./util";

import { ImageRetriever, setImageRetriever } from "./io/canvas";
import { _initPathKit } from "./misc/pathkit";

/**
 * Initialize Cuttle's internal PathKit instance. PathKit is used for boolean
 * operations.
 *
 * @remarks
 * This function should be called only once on application start.
 *
 * @internal
 */
export const initCuttleGeometry = async (imageRetriever: ImageRetriever) => {
  await _initPathKit();
  setImageRetriever(imageRetriever);
};
