import {
  ExampleCategory,
  CuttleSymbolExampleShape,
  EmojiData,
  makeEmojiExampleShape,
} from "./examples-types";
import * as builtin from "./model/builtin-shapes";

export const basicShapesConfig: ExampleCategory = {
  category: "Basic Shapes",
  display: "one-up-compact",
  featuredShapes: [],
  shapes: [
    { type: "builtin", definition: builtin.TextDefinition },
    { type: "builtin", definition: builtin.CircleDefinition },
    { type: "builtin", definition: builtin.RectangleDefinition },
    { type: "builtin", definition: builtin.PolygonDefinition },
    { type: "builtin", definition: builtin.StarDefinition },
    { type: "builtin", definition: builtin.LineDefinition },
    { type: "builtin", definition: builtin.EmojiDefinition },
  ],
};

export const cuttleSymbolsShapes: CuttleSymbolExampleShape[] = [
  {
    // Names need to be unique, so I added "Symbol" to avoid conflict with
    // "Heart" and "Star" in Basic Shapes
    name: "Heart Symbol",
    keywords: ["love"],
    path: "/symbols/cuttle-heart",
    type: "cuttle-symbol",
  },
  {
    name: "Wing Left",
    keywords: ["angel", "bird"],
    path: "/symbols/cuttle-wing-left",
    type: "cuttle-symbol",
  },
  {
    name: "Wing Right",
    keywords: ["angel", "bird"],
    path: "/symbols/cuttle-wing-right",
    type: "cuttle-symbol",
  },
  { name: "Star Symbol", path: "/symbols/cuttle-star", type: "cuttle-symbol" },
  { name: "Music Note", path: "/symbols/cuttle-music", type: "cuttle-symbol" },
  {
    name: "Paw",
    keywords: ["pet", "cat", "dog"],
    path: "/symbols/cuttle-paw",
    type: "cuttle-symbol",
  },
  {
    name: "Paw Outline",
    keywords: ["pet", "cat", "dog"],
    path: "/symbols/cuttle-paw-negative",
    type: "cuttle-symbol",
  },
];

export const examplesConfig: ExampleCategory[] = [
  {
    category: "Basic Shapes",
    display: "four-up",
    displayMore: "one-up",
    featuredShapes: [
      "Circle",
      "Rectangle",
      "Polygon",
      "Star",
      "Pill",
      "Triangle",
      "Heart",
      "Award Seal",
    ],
    shapes: [
      { type: "builtin", definition: builtin.CircleDefinition },
      { type: "builtin", definition: builtin.CircleFromCenterAndPointDefinition },
      { type: "builtin", definition: builtin.CircleFromThreePointsDefinition },
      { type: "builtin", definition: builtin.CircleFromTwoPointsDefinition },
      { type: "builtin", definition: builtin.RectangleDefinition, keywords: ["square"] },
      {
        type: "builtin",
        definition: builtin.PolygonDefinition,
        keywords: ["pentagon", "hexagon", "heptagon", "septagon", "octagon"],
      },
      {
        type: "builtin",
        definition: builtin.PolygonFromSideDefinition,
        keywords: ["pentagon", "hexagon", "heptagon", "septagon", "octagon"],
      },
      { type: "builtin", definition: builtin.StarDefinition },

      {
        type: "guts",
        name: "Triangle",
        project: "Creation-Panel-Basic-18DXskOcFMRw",
        isAutoScale: true,
      },
      {
        type: "guts",
        name: "Pill",
        project: "Creation-Panel-Basic-18DXskOcFMRw",
        isAutoScale: true,
      },
      {
        type: "guts",
        name: "Award Seal",
        project: "Creation-Panel-Basic-18DXskOcFMRw",
        isAutoScale: true,
      },
      {
        type: "component",
        name: "Heart",
        project: "Creation-Panel-Basic-18DXskOcFMRw",
        isAutoScale: true,
      },
      {
        type: "guts",
        name: "Semicircle",
        project: "Creation-Panel-Basic-18DXskOcFMRw",
        isAutoScale: true,
        keywords: ["hemicircle", "hemi", "semi", "half"],
      },
      {
        type: "component",
        name: "Pie Slice",
        project: "Pie-Slice-aa4ykLEI7BNn",
        isAutoScale: true,
        keywords: ["circle", "arc"],
      },
      {
        type: "component",
        name: "Moon Phase",
        project: "Moon-Phase-qsU7wJ07Kl0B",
        isAutoScale: true,
        keywords: ["circle", "full", "half", "quarter", "crescent"],
      },

      {
        type: "component",
        name: "Flexed Rectangle",
        project: "Warped-Text-MGWZ6wZxoMWe",
        isAutoScale: true,
      },
      {
        type: "component",
        name: "Wavy Rectangle",
        project: "Warped-Text-MGWZ6wZxoMWe",
        isAutoScale: true,
      },
      {
        type: "component",
        name: "Banner Rectangle",
        project: "Warped-Text-MGWZ6wZxoMWe",
        isAutoScale: true,
      },
      {
        type: "component",
        name: "Trapezoid",
        project: "Warped-Text-MGWZ6wZxoMWe",
        isAutoScale: true,
      },

      // Right Triangles
      {
        type: "guts",
        name: "Right Triangle (Top Left)",
        project: "Creation-Panel-Right-Triangles-nsRda25noCPa",
        isAutoScale: true,
      },
      {
        type: "guts",
        name: "Right Triangle (Top Right)",
        project: "Creation-Panel-Right-Triangles-nsRda25noCPa",
        isAutoScale: true,
      },
      {
        type: "guts",
        name: "Right Triangle (Bottom Right)",
        project: "Creation-Panel-Right-Triangles-nsRda25noCPa",
        isAutoScale: true,
      },
      {
        type: "guts",
        name: "Right Triangle (Bottom Left)",
        project: "Creation-Panel-Right-Triangles-nsRda25noCPa",
        isAutoScale: true,
      },
    ],
  },
  {
    category: "Lines & Curves",
    display: "four-up",
    displayMore: "one-up",
    featuredShapes: [
      "Line",
      "Arc From Center And Points",
      "Archimedean Spiral",
      "Logarithmic Spiral",
      "Straight Arrow",
      "Curved Arrow",
    ],
    shapes: [
      { type: "builtin", definition: builtin.LineDefinition },
      { type: "builtin", definition: builtin.ArcFromCenterAndPointsDefinition },
      { type: "builtin", definition: builtin.ArcFromCenterAndAnglesDefinition },
      { type: "builtin", definition: builtin.ArcFromThreePointsDefinition },
      { type: "builtin", definition: builtin.ArchimedeanSpiralDefinition },
      { type: "builtin", definition: builtin.LogarithmicSpiralDefinition },
      { type: "builtin", definition: builtin.SmoothFunctionPlotDefinition },

      {
        type: "component",
        name: "Straight Arrow",
        project: "Creation-Panel-Basic-18DXskOcFMRw",
        thumbnail: "Straight Arrow Thumbnail",
        isAutoScale: true,
      },
      {
        type: "component",
        name: "Curved Arrow",
        project: "Creation-Panel-Basic-18DXskOcFMRw",
        thumbnail: "Curved Arrow Thumbnail",
        isAutoScale: true,
      },
    ],
  },
  {
    category: "Text",
    display: "two-up",
    featuredShapes: [
      "Basic Text",
      "Single Line Text",
      "Stencil Text",
      "Connected Text",
      "Fit Text",
      "Text Along Path",
      "Text Around Circle (Top)",
      "Text Around Circle (Bottom)",
    ],
    shapes: [
      {
        name: "Basic Text",
        project: "Creation-Panel-Text-oIE1LQku0C1n",
        type: "guts",
        thumbnail: "Basic Text Thumbnail",
      },
      {
        name: "Single Line Text",
        project: "Creation-Panel-Text-oIE1LQku0C1n",
        type: "guts",
        thumbnail: "Single Line Text Thumbnail",
      },
      {
        name: "Stencil Text",
        project: "Creation-Panel-Text-oIE1LQku0C1n",
        type: "guts",
        thumbnail: "Stencil Text Thumbnail",
      },
      {
        name: "Fit Text",
        project: "Creation-Panel-Text-oIE1LQku0C1n",
        type: "guts",
        thumbnail: "Fit Text Thumbnail",
      },
      {
        name: "Text Around Circle (Top)",
        project: "Creation-Panel-Text-oIE1LQku0C1n",
        type: "guts",
        thumbnail: "Text Around Circle (Top) Thumbnail",
      },
      {
        name: "Text Around Circle (Bottom)",
        project: "Creation-Panel-Text-oIE1LQku0C1n",
        type: "guts",
        thumbnail: "Text Around Circle (Bottom) Thumbnail",
      },
      {
        name: "Text Around Circle (Left)",
        project: "Creation-Panel-Text-oIE1LQku0C1n",
        type: "guts",
        thumbnail: "Text Around Circle (Left) Thumbnail",
      },
      {
        name: "Text Around Circle (Right)",
        project: "Creation-Panel-Text-oIE1LQku0C1n",
        type: "guts",
        thumbnail: "Text Around Circle (Right) Thumbnail",
      },
      {
        name: "Text Along Path",
        componentName: "Text Along Path A",
        project: "Creation-Panel-Text-oIE1LQku0C1n",
        type: "guts",
        thumbnail: "Text Along Path Thumbnail",
      },
      {
        name: "Spiral Text",
        project: "Creation-Panel-Text-oIE1LQku0C1n",
        type: "guts",
        thumbnail: "Spiral Text Thumbnail",
      },
      { type: "builtin", definition: builtin.ConnectedTextDefinition },
      {
        type: "builtin",
        definition: builtin.ConnectedTextWithTailsDefinition,
        keywords: ["tail", "heart", "connector", "swash", "pua"],
      },
    ],
  },
  {
    category: "Warped Text",
    display: "two-up",
    featuredShapes: [
      "Banner Text (Slope Down)",
      "Arched Text (Up)",
      "Pinched Text",
      "Stretched Text",
      "Leaned Text (Grow)",
      "Custom Warped Text",
    ],
    shapes: [
      { name: "Banner Text (Slope Down)", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Banner Text (Slope Up)", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Banner Text (Shrink)", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Banner Text (Grow)", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Leaned Text (Grow)", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Leaned Text (Shrink)", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Pinched Text", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Pinched Text (Horizontal)", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Pinched Text (Vertical)", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Stretched Text", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Stretched Text (Horizontal)", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Stretched Text (Vertical)", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Arched Text (Up)", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Arched Text (Down)", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Arched Text (Bottom Up)", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Arched Text (Top Down)", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Waved Text (Up)", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Waved Text (Down)", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Waved Text (Mirrored Up)", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Waved Text (Mirrored Down)", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
      { name: "Custom Warped Text", project: "Warped-Text-MGWZ6wZxoMWe", type: "guts" },
    ],
  },
  {
    category: "Cuttle Symbols",
    display: "four-up",
    featuredShapes: [
      "Heart Symbol",
      "Wing Left",
      "Wing Right",
      "Star Symbol",
      "Music Note",
      "Paw",
      "Paw Outline",
    ],
    shapes: cuttleSymbolsShapes,
  },
  {
    category: "Emoji",
    display: "four-up",
    featuredShapes: [
      "Slightly Smiling Face",
      "Face With Tears Of Joy",
      "Dog Face",
      "Cat Face",
      "Avocado",
      "Cherries",
      "Thumbs Up Sign",
      "Crescent Moon",
    ],
    shapes: [
      // NOTE: Emoji are loaded from external JSON by shapeIndex.load
    ],
  },
  {
    category: "Advanced",
    display: "four-up",
    displayMore: "one-up",
    featuredShapes: ["Auto Layout"],
    shapes: [
      {
        name: "Auto Layout",
        project: "Creation-Panel-Auto-Layout-A3IikkEVvVWj",
        type: "component",
        thumbnail: "Auto Layout Thumb",
      },
    ],
  },
];

export const addExampleShapes = (categoryName: "Emoji", emojiData: EmojiData[]) => {
  const emojiExampleCategory = examplesConfig.find(({ category }) => category === categoryName);
  if (emojiExampleCategory) {
    // TODO: other categories like maps, brands
    if (categoryName === "Emoji") {
      emojiExampleCategory.shapes = emojiData.map(makeEmojiExampleShape);
    }
  }
};
