import {
  AffineMatrix,
  Anchor,
  Axis,
  BoundingBox,
  ClosestPointResultWithTime,
  CubicSegment,
  GEOMETRIC_TOLERANCE,
  Geometry,
  GeometryPrimitive,
  Path,
  Ray,
  Vec,
  pointsAreCollinear,
  saturate,
} from "..";
import {
  isInternalIntersection,
  lineAxisIntersection,
  lineCubicIntersections,
  lineLineIntersection,
  lineRayIntersection,
} from "../op/intersection";
import { segmentOverlap } from "../op/overlap";
import { timeAtClosestPointOnAxis, timeAtPointOnLine } from "./primitive-utils";

/**
 * A linear segment of a path.
 */
export class LineSegment extends Geometry implements GeometryPrimitive {
  static readonly displayName = "LineSegment";

  /** The start position */
  s: Vec;

  /** The end position */
  e: Vec;

  /**
   * Source information is specified on segments that are extracted from paths
   * for the purpose of intersection. This allows us to avoid finding
   * intersections between the endpoints of adjacent segments.
   * @internal
   */
  source?: { path: Path; time: number };

  /**
   * Constructs a line segment.
   *
   * @param s
   * @param e
   */
  constructor(s = new Vec(), e = new Vec()) {
    super();
    this.s = s;
    this.e = e;
  }

  clone() {
    return new LineSegment(this.s.clone(), this.e.clone());
  }

  isValid() {
    return Vec.isValid(this.s) && Vec.isValid(this.e);
  }

  /**
   * For interface compatibility with CubicSegment.
   *
   * @returns `true` since line segments are always linear.
   */
  isLinear() {
    return true;
  }
  isStraight() {
    return true;
  }

  isHorizontal() {
    return this.s.y === this.e.y;
  }
  isVertical() {
    return this.s.x === this.e.x;
  }

  isTangentToNext(segment: LineSegment | CubicSegment, tolerance?: number) {
    const p3 = segment instanceof CubicSegment ? segment.cs : segment.e;
    return pointsAreCollinear(this.s, this.e, p3, tolerance);
  }

  /**
   * @returns `true` if the segment is exactly equal to `segment`.
   * @param segment Another line segment
   */
  equals(segment: LineSegment) {
    return this.s.equals(segment.s) && this.e.equals(segment.e);
  }

  _almostEquals(segment: LineSegment, tolerance?: number) {
    return this.s._almostEquals(segment.s, tolerance) && this.e._almostEquals(segment.e, tolerance);
  }
  _almostEqualsReverse(segment: LineSegment, tolerance?: number) {
    return this.s._almostEquals(segment.e, tolerance) && this.e._almostEquals(segment.s, tolerance);
  }

  affineTransform(affineMatrix: AffineMatrix) {
    this.s.affineTransform(affineMatrix);
    this.e.affineTransform(affineMatrix);
    return this;
  }
  affineTransformWithoutTranslation(affineMatrix: AffineMatrix) {
    this.s.affineTransformWithoutTranslation(affineMatrix);
    this.e.affineTransformWithoutTranslation(affineMatrix);
    return this;
  }

  setFromAnchors(startAnchor: Anchor, endAnchor: Anchor) {
    this.s = startAnchor.position;
    this.e = endAnchor.position;
    return this;
  }

  reverse() {
    [this.s, this.e] = [this.e, this.s];
    return this;
  }

  /**
   * @returns the distance from the start to the end of the segment.
   */
  length() {
    return this.s.distance(this.e);
  }

  /**
   * @returns the time at `distance` along the along the segment.
   * @param distance
   */
  timeAtDistance(distance: number) {
    return distance / this.length();
  }

  /**
   * @returns the distance along the segment at `time`.
   * @param time A segment time between 0 and 1
   */
  distanceAtTime(time: number) {
    return this.length() * time;
  }

  /**
   * @returns the position on the segment at `time`.
   * @param time A segment time between 0 and 1
   */
  positionAtTime(time: number) {
    return this.s.clone().mix(this.e, time);
  }

  /**
   * @returns the first derivative of the segment at `time`.
   *
   * Line segments have a constant derivative, so this method will return the
   * same value regardless of the specified `time`. This function takes `time`
   * to keep segment and path interfaces consistent.
   *
   * @param time A segment time between 0 and 1
   */
  derivativeAtTime(time: number) {
    return this.e.clone().sub(this.s);
  }

  /**
   * @returns the second derivative of the segment at `time`.
   *
   * The second derivative of a line segment is zero since its velocity is
   * constant.
   *
   * @param time A segment time between 0 and 1
   */
  secondDerivativeAtTime(time: number) {
    return new Vec(0, 0);
  }

  /**
   * @returns the curvature of the segment at `time`.
   *
   * The curvature of a line segment is always zero, since it isn't curved!
   *
   * @param time A segment time between 0 and 1
   */
  curvatureAtTime(time: number) {
    return 0;
  }

  /**
   * @returns a unit-length vector tangent to the segment at `time`.
   * @param time A segment time between 0 and 1
   */
  tangentAtTime(time: number) {
    return this.derivativeAtTime(time).normalize();
  }

  /**
   * @returns a unit-length vector perpendicular to the segment at `time`.
   * @param time A segment time between 0 and 1
   */
  normalAtTime(time: number) {
    return this.tangentAtTime(time).rotate90();
  }

  timeAtPoint(point: Vec, tolerance = GEOMETRIC_TOLERANCE) {
    return timeAtPointOnLine(this.s, this.e, point, tolerance);
  }

  closestPoint(point: Vec, areaOfInterest?: BoundingBox): ClosestPointResultWithTime | undefined {
    if (areaOfInterest && !this._looseOverlapsBoundingBox(areaOfInterest)) return;

    const { s, e } = this;
    const time = saturate(
      timeAtClosestPointOnAxis(s.x, s.y, e.x - s.x, e.y - s.y, point.x, point.y)
    );
    const position = this.positionAtTime(time);

    if (areaOfInterest && !areaOfInterest.containsPoint(position)) return;

    return { position, time, geometry: this };
  }

  boundingBox() {
    const { s, e } = this;
    return new BoundingBox(
      new Vec(Math.min(s.x, e.x), Math.min(s.y, e.y)),
      new Vec(Math.max(s.x, e.x), Math.max(s.y, e.y))
    );
  }
  looseBoundingBox() {
    return this.boundingBox();
  }

  /**
   * @returns a new line segment between `startTime` and `endTime`.
   * @param startTime A segment time between 0 and 1
   * @param endTime A segment time between 0 and 1
   */
  slice(startTime: number, endTime: number) {
    return new LineSegment(this.positionAtTime(startTime), this.positionAtTime(endTime));
  }

  splitAtTime(time: number) {
    const mid = this.positionAtTime(time);
    return [new LineSegment(this.s, mid), new LineSegment(mid, this.e)];
  }

  isContainedByBoundingBox(box: BoundingBox): boolean {
    return box.containsPoint(this.s) && box.containsPoint(this.e);
  }

  isIntersectedByBoundingBox(box: BoundingBox): boolean {
    return box.containsPoint(this.s) !== box.containsPoint(this.e);
  }

  isOverlappedByBoundingBox(box: BoundingBox): boolean {
    return this._looseOverlapsBoundingBox(box);
  }

  _looseOverlapsBoundingBox(box: BoundingBox) {
    const { s, e } = this;
    const minx = Math.min(s.x, e.x);
    const maxx = Math.max(s.x, e.x);
    const miny = Math.min(s.y, e.y);
    const maxy = Math.max(s.y, e.y);
    return maxx >= box.min.x && minx <= box.max.x && maxy >= box.min.y && miny <= box.max.y;
  }

  _intersectionWithLineSegment(segment: LineSegment) {
    const intersection = lineLineIntersection(this.s, this.e, segment.s, segment.e);
    if (intersection && !isInternalIntersection(this, segment, intersection)) {
      return intersection;
    }
    return undefined;
  }

  _intersectionsWithCubicSegment(segment: CubicSegment) {
    const results = lineCubicIntersections(
      this.s,
      this.e,
      segment.s,
      segment.cs,
      segment.ce,
      segment.e
    );
    return results.filter((result) => {
      if (result.time1 < 0 || result.time1 > 1) return false;
      if (isInternalIntersection(this, segment, result)) return false;
      return true;
    });
  }

  _intersectionWithAxis(axis: Axis) {
    return lineAxisIntersection(this.s, this.e, axis.origin, axis.direction);
  }

  _intersectionWithRay(ray: Ray) {
    return lineRayIntersection(this.s, this.e, ray.origin, ray.direction);
  }

  _intersectionsWithPrimitive(primitive: GeometryPrimitive) {
    if (primitive instanceof LineSegment) {
      const x = this._intersectionWithLineSegment(primitive);
      if (x) return [x];
    } else if (primitive instanceof CubicSegment) {
      return this._intersectionsWithCubicSegment(primitive);
    } else if (primitive instanceof Axis) {
      const x = this._intersectionWithAxis(primitive);
      if (x) return [x];
    } else if (primitive instanceof Ray) {
      const x = this._intersectionWithRay(primitive);
      if (x) return [x];
    }
    return [];
  }

  _overlapWithPrimitive(primitive: GeometryPrimitive, tolerance: number) {
    if (primitive instanceof LineSegment || primitive instanceof CubicSegment) {
      return segmentOverlap(this, primitive, tolerance);
    }
    return undefined;
  }

  _primitives() {
    return [this];
  }

  /**
   * @returns the signed area (orienation matters) inside a triangle between
   * this line segment and the origin. Used in path area calculation.
   */
  _signedArea() {
    return 0.5 * this.s.cross(this.e);
  }

  static isValid(a: unknown): a is LineSegment {
    return a instanceof LineSegment && a.isValid();
  }

  /**
   * Constructs a line segment from two anchors.
   *
   * Since this constructs a line segment, anchor handles will be ignored.
   *
   * @param startAnchor
   * @param endAnchor
   */
  static fromAnchors(startAnchor: Anchor, endAnchor: Anchor) {
    return new LineSegment(startAnchor.position, endAnchor.position);
  }
}
