import m from "mithril";
import { AffineMatrix, Graphic, Vec } from "../geom/index";
import { paintGraphicToCanvas } from "../geom/io/canvas";
import { globalState } from "../global-state";
import { CustomCanvas } from "./custom-canvas";
import { devicePixelRatio } from "./util";

const placeholderThumbnail = (width: number, height: number) => {
  return m(".thumbnail", { style: `width:${width}px;height:${height}px` });
};

interface ThumbnailColorAttrs {
  graphic: Graphic | undefined;
  width: number;
  height: number;
  padding: number;
}
interface ThumbnailColorState {
  latestPixelRatio?: number;
}
export const ThumbnailColor: m.Component<ThumbnailColorAttrs, ThumbnailColorState> = {
  onbeforeupdate(vnode, old) {
    if (globalState.isFirstRedrawAfterBecameVisible) return true;
    // Skip re-rendering thumbnails for memoized components. A memoized
    // component's "result" will be triple-equal to it's previous value, since
    // the value is cached.
    const isSame =
      vnode.attrs.graphic === old.attrs.graphic && this.latestPixelRatio === devicePixelRatio();
    if (isSame) {
      return false; // Prevent calling view.
    }
    return true;
  },
  onupdate() {
    // Stash the last pixel ratio so we know when the thumbnail needs to be
    // updated again in onbeforeupdate.
    this.latestPixelRatio = devicePixelRatio();
  },
  view({ attrs: { graphic, width, height, padding } }) {
    if (!graphic) {
      return placeholderThumbnail(width, height);
    }

    const containerSize = new Vec(width - padding * 2, height - padding * 2);
    const boundingBox = graphic.looseBoundingBox();

    if (!boundingBox) return placeholderThumbnail(width, height);

    let size = boundingBox.size();
    if (size.isZero()) {
      boundingBox.expandScalar(0.5);
      size.set(1, 1);
    }

    // Calculate offset for centering
    const sizeRatio = size.x / size.y;
    const containerSizeRatio = containerSize.x / containerSize.y;
    let offset: Vec;
    if (sizeRatio > containerSizeRatio) {
      const difference = size.x / containerSizeRatio - size.y;
      offset = new Vec(0, difference / 2);
    } else {
      const difference = size.y * containerSizeRatio - size.x;
      offset = new Vec(difference / 2, 0);
    }

    const scale = Math.min(containerSize.x / size.x, containerSize.y / size.y);

    const viewMatrix = AffineMatrix.fromTransform({ position: new Vec(padding, padding) })
      .mul(AffineMatrix.fromTransform({ scale }))
      .mul(AffineMatrix.fromTransform({ position: offset.clone().sub(boundingBox.min) }));

    const graphicTransformed = graphic.clone().affineTransform(viewMatrix).scaleStroke(scale);

    const renderThumbnail = (viewportSize: Vec, ctx: CanvasRenderingContext2D) => {
      ctx.clearRect(0, 0, viewportSize.x, viewportSize.y);
      paintGraphicToCanvas(graphicTransformed, ctx, viewportSize, {
        hairlineStrokeWidth: 0.5 / (this.latestPixelRatio || 1),
      });
    };

    return m(
      ".thumbnail-color",
      { style: `width:${width}px; height:${height}px;` },
      m(CustomCanvas, {
        viewportSize: new Vec(width, height),
        render: renderThumbnail,
      })
    );
  },
};
