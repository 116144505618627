import { CompoundPath, Graphic, Group } from "..";
import {
  connectGlyph,
  connectGlyphToLine,
  ConnectOptions,
  ConnectToLineOptions,
} from "../op/connect-text";

/**
 * [Freetype's article on glyph metrics](http://www.freetype.org/freetype2/docs/glyphs/glyphs-3.html) goes in depth about the various metrics.
 *
 * ![glyph metrics labeled](https://cuttle-uploads.imgix.net/63/LLOrO76AAhFjhlDRHxsKpnrf.png)
 *
 * We're thinking about a [parametric Cuttle version](https://cuttle.xyz/@forresto/Font-Glyph-Metrics-KnONP5G2WnvS) of this image for any font/glyph. 🧐
 */
export class FontGlyph {
  constructor(
    /**
     * The glyph name (e.g. "Aring", "five")
     */
    public name: string,

    /**
     * Distance along path, including advanceWidth and kerning of previous
     * characters.
     *
     * @deprecated
     */
    public x: number,

    /**
     * The width to advance when drawing this glyph. We use this to find the
     * "designed" origin of the glyph. Advance width does not change in response
     * to letter spacing or kerning. For a value that includes these
     * adjustments, see `advanceX`.
     */
    public advanceWidth: number,

    /**
     * The amount to move the cursor from the previous cursor position when
     * drawing this glyph. Unlike `advanceWidth`, this value incorporates things
     * like letter spacing and kerning.
     */
    public advanceX: number,

    /**
     * The geometry associated with the glyph. This is currently always a
     * CompoundPath. Glyph geometry is not pre-transformed, so you'll probably
     * need to translate this by `x`.
     */
    public geometry: Graphic
  ) {}

  clone() {
    return new FontGlyph(
      this.name,
      this.x,
      this.advanceWidth,
      this.advanceX,
      this.geometry.clone()
    );
  }

  isBlank() {
    if (this.geometry instanceof CompoundPath) {
      return this.geometry.paths.length === 0;
    }
    if (this.geometry instanceof Group) {
      return this.geometry.items.length === 0;
    }
    return false;
  }

  /**
   * Connects dots on i's, accents, and other disconnected paths. Connection is
   * performed toward to the closest path to the baseline. To ensure boolean
   * operations work correctly after connection, the glyph's geometry will be
   * broken into a group.
   *
   * @chainable
   * @internal
   */
  connect(options?: FontGlyphConnectOptions) {
    connectGlyph(this, options);
    return this;
  }

  /**
   * Connects a glyph to the end of a line of glyphs. The text direction is
   * assumed to be left-to-right. Connection modifies the glyph's `advanceX`,
   * assuming it will follow the the glyphs in `lineGlyphs`.
   *
   * @chainable
   * @internal
   */
  connectToLine(lineGlyphs: FontGlyph[], options?: FontGlyphConnectToLineOptions) {
    connectGlyphToLine(this, lineGlyphs, options);
    return this;
  }
}

export type FontGlyphConnectOptions = ConnectOptions;
export type FontGlyphConnectToLineOptions = ConnectToLineOptions;
