
var PathKitInit = (() => {
  var _scriptName = typeof document != 'undefined' ? document.currentScript?.src : undefined;
  if (typeof __filename != 'undefined') _scriptName = _scriptName || __filename;
  return (
function(moduleArg = {}) {
  var moduleRtn;

var n=moduleArg,aa,ba,ca=new Promise((a,b)=>{aa=a;ba=b}),da="object"==typeof window,ea="function"==typeof importScripts,fa="object"==typeof process&&"object"==typeof process.versions&&"string"==typeof process.versions.node&&"renderer"!=process.type;
(function(a){var b={};a.loadCmdsTypedArray=function(f){for(var k=0,m=0;m<f.length;m++)k+=f[m].length;if(b[k])var l=b[k];else l=new Float32Array(k),b[k]=l;var p=0;for(m=0;m<f.length;m++)for(var r=0;r<f[m].length;r++){var w=f[m][r];"string"===typeof w&&(w=a.SkBits2FloatUnsigned(parseInt(w)));l[p]=w;p++}f=a._malloc(l.length*l.BYTES_PER_ELEMENT);a.HEAPF32.set(l,f/l.BYTES_PER_ELEMENT);return[f,k]};a.FromCmds=function(f){f=a.loadCmdsTypedArray(f);var k=a._FromCmds(f[0],f[1]);a._free(f[0]);return k};var c,
d,e,g,h;a.cubicYFromX=function(f,k,m,l,p){c&&d===f&&e===k&&g===m&&h===l||(c&&c.delete(),c=new a._SkCubicMap([f,k],[m,l]),d=f,e=k,g=m,h=l);return c.computeYFromX(p)};a.cubicPtFromT=function(f,k,m,l,p){c&&d===f&&e===k&&g===m&&h===l||(c&&c.delete(),c=new a._SkCubicMap([f,k],[m,l]),d=f,e=k,g=m,h=l);return c.computePtFromT(p)}})(n);
(function(a){a.onRuntimeInitialized=function(){a.SkPath.prototype.addPath=function(){var b=arguments[0];if(1===arguments.length)this._addPath(b,1,0,0,0,1,0,0,0,1);else if(2===arguments.length){var c=arguments[1];this._addPath(b,c.a,c.c,c.e,c.b,c.d,c.f,0,0,1)}else if(7===arguments.length)c=arguments,this._addPath(b,c[1],c[3],c[5],c[2],c[4],c[6],0,0,1);else if(10===arguments.length)c=arguments,this._addPath(b,c[1],c[2],c[3],c[4],c[5],c[6],c[7],c[8],c[9]);else return console.Ma("addPath expected to take 1, 2, 7, or 10 args. Got "+
arguments.length),null;return this};a.SkPath.prototype.reverseAddPath=function(b){this._reverseAddPath(b);return this};a.SkPath.prototype.arc=function(b,c,d,e,g,h){this._arc(b,c,d,e,g,!!h);return this};a.SkPath.prototype.arcTo=function(b,c,d,e,g){this._arcTo(b,c,d,e,g);return this};a.SkPath.prototype.bezierCurveTo=function(b,c,d,e,g,h){this._cubicTo(b,c,d,e,g,h);return this};a.SkPath.prototype.close=function(){this._close();return this};a.SkPath.prototype.closePath=function(){this._close();return this};
a.SkPath.prototype.conicTo=function(b,c,d,e,g){this._conicTo(b,c,d,e,g);return this};a.SkPath.prototype.cubicTo=function(b,c,d,e,g,h){this._cubicTo(b,c,d,e,g,h);return this};a.SkPath.prototype.dash=function(b,c,d){return this._dash(b,c,d)?this:null};a.SkPath.prototype.ellipse=function(b,c,d,e,g,h,f,k){this._ellipse(b,c,d,e,g,h,f,!!k);return this};a.SkPath.prototype.lineTo=function(b,c){this._lineTo(b,c);return this};a.SkPath.prototype.moveTo=function(b,c){this._moveTo(b,c);return this};a.SkPath.prototype.op=
function(b,c){return this._op(b,c)?this:null};a.SkPath.prototype.quadraticCurveTo=function(b,c,d,e){this._quadTo(b,c,d,e);return this};a.SkPath.prototype.quadTo=function(b,c,d,e){this._quadTo(b,c,d,e);return this};a.SkPath.prototype.rect=function(b,c,d,e){this._rect(b,c,d,e);return this};a.SkPath.prototype.simplify=function(){return this._simplify()?this:null};a.SkPath.prototype.asWinding=function(){return this._asWinding()?this:null};a.SkPath.prototype.isEmpty=function(){return this._isEmpty()};
a.SkPath.prototype.stroke=function(b){b=b||{};b.width=b.width||1;b.miter_limit=b.miter_limit||4;b.cap=b.cap||a.StrokeCap.BUTT;b.join=b.join||a.StrokeJoin.MITER;b.res_scale=b.res_scale||1;return this._stroke(b)?this:null};a.SkPath.prototype.transform=function(){if(1===arguments.length)this._transform(arguments[0]);else if(9===arguments.length){var b=arguments;this._transform(b[0],b[1],b[2],b[3],b[4],b[5],b[6],b[7],b[8])}else return console.Ma("transform expected to take 1 or 9 arguments. Got "+arguments.length),
null;return this};a.SkPath.prototype.trim=function(b,c,d){return this._trim(b,c,!!d)?this:null}}})(n);var ha=Object.assign({},n),q="",ia,ja;
if(fa){var fs=require("fs"),ka=require("path");q=__dirname+"/";ja=a=>{a=la(a)?new URL(a):ka.normalize(a);return fs.readFileSync(a)};ia=a=>{a=la(a)?new URL(a):ka.normalize(a);return new Promise((b,c)=>{fs.readFile(a,void 0,(d,e)=>{d?c(d):b(e.buffer)})})};process.argv.slice(2)}else if(da||ea)ea?q=self.location.href:"undefined"!=typeof document&&document.currentScript&&(q=document.currentScript.src),_scriptName&&(q=_scriptName),q.startsWith("blob:")?q="":q=q.substr(0,q.replace(/[?#].*/,"").lastIndexOf("/")+
1),ea&&(ja=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),ia=a=>la(a)?new Promise((b,c)=>{var d=new XMLHttpRequest;d.open("GET",a,!0);d.responseType="arraybuffer";d.onload=()=>{200==d.status||0==d.status&&d.response?b(d.response):c(d.status)};d.onerror=c;d.send(null)}):fetch(a,{credentials:"same-origin"}).then(b=>b.ok?b.arrayBuffer():Promise.reject(Error(b.status+" : "+b.url)));var ma=console.log.bind(console),u=console.error.bind(console);
Object.assign(n,ha);ha=null;var na,oa=!1,pa,x,y,A,B,D,qa,ra;function sa(){var a=na.buffer;pa=new Int8Array(a);y=new Int16Array(a);x=new Uint8Array(a);A=new Uint16Array(a);B=new Int32Array(a);D=new Uint32Array(a);n.HEAPF32=qa=new Float32Array(a);ra=new Float64Array(a)}var ta=[],ua=[],va=[],E=0,wa=null,F=null;function xa(a){a="Aborted("+a+")";u(a);oa=!0;a=new WebAssembly.RuntimeError(a+". Build with -sASSERTIONS for more info.");ba(a);throw a;}
var ya=a=>a.startsWith("data:application/octet-stream;base64,"),la=a=>a.startsWith("file://"),za;function Aa(a){return ia(a).then(b=>new Uint8Array(b),()=>{if(ja)var b=ja(a);else throw"both async and sync fetching of the wasm failed";return b})}function Ba(a,b,c){return Aa(a).then(d=>WebAssembly.instantiate(d,b)).then(c,d=>{u(`failed to asynchronously prepare wasm: ${d}`);xa(d)})}
function Ca(a,b){var c=za;return"function"!=typeof WebAssembly.instantiateStreaming||ya(c)||la(c)||fa||"function"!=typeof fetch?Ba(c,a,b):fetch(c,{credentials:"same-origin"}).then(d=>WebAssembly.instantiateStreaming(d,a).then(b,function(e){u(`wasm streaming compile failed: ${e}`);u("falling back to ArrayBuffer instantiation");return Ba(c,a,b)}))}var Da={},Ea=a=>{for(;a.length;){var b=a.pop();a.pop()(b)}};function G(a){return this.fromWireType(D[a>>2])}
var H={},I={},Fa={},J,M=(a,b,c)=>{function d(f){f=c(f);if(f.length!==a.length)throw new J("Mismatched type converter count");for(var k=0;k<a.length;++k)L(a[k],f[k])}a.forEach(f=>Fa[f]=b);var e=Array(b.length),g=[],h=0;b.forEach((f,k)=>{I.hasOwnProperty(f)?e[k]=I[f]:(g.push(f),H.hasOwnProperty(f)||(H[f]=[]),H[f].push(()=>{e[k]=I[f];++h;h===g.length&&d(e)}))});0===g.length&&d(e)},Ga={},Ha,N=a=>{for(var b="";x[a];)b+=Ha[x[a++]];return b},O;
function Ia(a,b,c={}){var d=b.name;if(!a)throw new O(`type "${d}" must have a positive integer typeid pointer`);if(I.hasOwnProperty(a)){if(c.Qa)return;throw new O(`Cannot register type '${d}' twice`);}I[a]=b;delete Fa[a];H.hasOwnProperty(a)&&(b=H[a],delete H[a],b.forEach(e=>e()))}function L(a,b,c={}){return Ia(a,b,c)}
var Ka=a=>{throw new O(a.fa.ia.ga.name+" instance already deleted");},La=!1,Ma=()=>{},Na=(a,b,c)=>{if(b===c)return a;if(void 0===c.la)return null;a=Na(a,b,c.la);return null===a?null:c.La(a)},Oa={},P=[],Pa=()=>{for(;P.length;){var a=P.pop();a.fa.ra=!1;a["delete"]()}},Q,R={},Qa=(a,b)=>{if(void 0===b)throw new O("ptr should not be undefined");for(;a.la;)b=a.va(b),a=a.la;return R[b]},Ra=(a,b)=>{if(!b.ia||!b.ha)throw new J("makeClassHandle requires ptr and ptrType");if(!!b.pa!==!!b.ja)throw new J("Both smartPtrType and smartPtr must be specified");
b.count={value:1};return S(Object.create(a,{fa:{value:b,writable:!0}}))},S=a=>{if("undefined"===typeof FinalizationRegistry)return S=b=>b,a;La=new FinalizationRegistry(b=>{b=b.fa;--b.count.value;0===b.count.value&&(b.ja?b.pa.oa(b.ja):b.ia.ga.oa(b.ha))});S=b=>{var c=b.fa;c.ja&&La.register(b,{fa:c},b);return b};Ma=b=>{La.unregister(b)};return S(a)};function Sa(){}
var T=(a,b)=>Object.defineProperty(b,"name",{value:a}),Ta=(a,b,c)=>{if(void 0===a[b].ka){var d=a[b];a[b]=function(...e){if(!a[b].ka.hasOwnProperty(e.length))throw new O(`Function '${c}' called with an invalid number of arguments (${e.length}) - expects one of (${a[b].ka})!`);return a[b].ka[e.length].apply(this,e)};a[b].ka=[];a[b].ka[d.wa]=d}},Ua=(a,b,c)=>{if(n.hasOwnProperty(a)){if(void 0===c||void 0!==n[a].ka&&void 0!==n[a].ka[c])throw new O(`Cannot register public name '${a}' twice`);Ta(n,a,a);
if(n.hasOwnProperty(c))throw new O(`Cannot register multiple overloads of a function with the same number of arguments (${c})!`);n[a].ka[c]=b}else n[a]=b,void 0!==c&&(n[a].Wa=c)},Va=a=>{if(void 0===a)return"_unknown";a=a.replace(/[^a-zA-Z0-9_]/g,"$");var b=a.charCodeAt(0);return 48<=b&&57>=b?`_${a}`:a};function Wa(a,b,c,d,e,g,h,f){this.name=a;this.constructor=b;this.sa=c;this.oa=d;this.la=e;this.Oa=g;this.va=h;this.La=f;this.Sa=[]}
var Xa=(a,b,c)=>{for(;b!==c;){if(!b.va)throw new O(`Expected null or instance of ${c.name}, got an instance of ${b.name}`);a=b.va(a);b=b.la}return a};function Ya(a,b){if(null===b){if(this.Ca)throw new O(`null is not a valid ${this.name}`);return 0}if(!b.fa)throw new O(`Cannot pass "${Za(b)}" as a ${this.name}`);if(!b.fa.ha)throw new O(`Cannot pass deleted object as a pointer of type ${this.name}`);return Xa(b.fa.ha,b.fa.ia.ga,this.ga)}
function $a(a,b){if(null===b){if(this.Ca)throw new O(`null is not a valid ${this.name}`);if(this.ya){var c=this.ua();null!==a&&a.push(this.oa,c);return c}return 0}if(!b||!b.fa)throw new O(`Cannot pass "${Za(b)}" as a ${this.name}`);if(!b.fa.ha)throw new O(`Cannot pass deleted object as a pointer of type ${this.name}`);if(!this.xa&&b.fa.ia.xa)throw new O(`Cannot convert argument of type ${b.fa.pa?b.fa.pa.name:b.fa.ia.name} to parameter type ${this.name}`);c=Xa(b.fa.ha,b.fa.ia.ga,this.ga);if(this.ya){if(void 0===
b.fa.ja)throw new O("Passing raw pointer to smart pointer is illegal");switch(this.Ua){case 0:if(b.fa.pa===this)c=b.fa.ja;else throw new O(`Cannot convert argument of type ${b.fa.pa?b.fa.pa.name:b.fa.ia.name} to parameter type ${this.name}`);break;case 1:c=b.fa.ja;break;case 2:if(b.fa.pa===this)c=b.fa.ja;else{var d=b.clone();c=this.Ta(c,U(()=>d["delete"]()));null!==a&&a.push(this.oa,c)}break;default:throw new O("Unsupporting sharing policy");}}return c}
function ab(a,b){if(null===b){if(this.Ca)throw new O(`null is not a valid ${this.name}`);return 0}if(!b.fa)throw new O(`Cannot pass "${Za(b)}" as a ${this.name}`);if(!b.fa.ha)throw new O(`Cannot pass deleted object as a pointer of type ${this.name}`);if(b.fa.ia.xa)throw new O(`Cannot convert argument of type ${b.fa.ia.name} to parameter type ${this.name}`);return Xa(b.fa.ha,b.fa.ia.ga,this.ga)}
function bb(a,b,c,d,e,g,h,f,k,m,l){this.name=a;this.ga=b;this.Ca=c;this.xa=d;this.ya=e;this.Ra=g;this.Ua=h;this.Ja=f;this.ua=k;this.Ta=m;this.oa=l;e||void 0!==b.la?this.toWireType=$a:(this.toWireType=d?Ya:ab,this.na=null)}
var cb=(a,b,c)=>{if(!n.hasOwnProperty(a))throw new J("Replacing nonexistent public symbol");void 0!==n[a].ka&&void 0!==c?n[a].ka[c]=b:(n[a]=b,n[a].wa=c)},db,eb=(a,b,c=[])=>{a.includes("j")?(a=a.replace(/p/g,"i"),b=(0,n["dynCall_"+a])(b,...c)):b=db.get(b)(...c);return b},fb=(a,b)=>(...c)=>eb(a,b,c),V=(a,b)=>{a=N(a);var c=a.includes("j")?fb(a,b):db.get(b);if("function"!=typeof c)throw new O(`unknown function pointer with signature ${a}: ${b}`);return c},gb,ib=a=>{a=hb(a);var b=N(a);W(a);return b},jb=
(a,b)=>{function c(g){e[g]||I[g]||(Fa[g]?Fa[g].forEach(c):(d.push(g),e[g]=!0))}var d=[],e={};b.forEach(c);throw new gb(`${a}: `+d.map(ib).join([", "]));},kb=(a,b)=>{for(var c=[],d=0;d<a;d++)c.push(D[b+4*d>>2]);return c};function lb(a){for(var b=1;b<a.length;++b)if(null!==a[b]&&void 0===a[b].na)return!0;return!1}
function mb(a,b,c,d,e){var g=b.length;if(2>g)throw new O("argTypes array size mismatch! Must at least get return value and 'this' types!");var h=null!==b[1]&&null!==c,f=lb(b),k="void"!==b[0].name,m=g-2,l=Array(m),p=[],r=[];return T(a,function(...w){r.length=0;p.length=h?2:1;p[0]=e;if(h){var t=b[1].toWireType(r,this);p[1]=t}for(var v=0;v<m;++v)l[v]=b[v+2].toWireType(r,w[v]),p.push(l[v]);w=d(...p);if(f)Ea(r);else for(v=h?1:2;v<b.length;v++){var C=1===v?t:l[v-2];null!==b[v].na&&b[v].na(C)}t=k?b[0].fromWireType(w):
void 0;return t})}
var ob=a=>{a=a.trim();const b=a.indexOf("(");return-1!==b?a.substr(0,b):a},pb=[],X=[],qb=a=>{9<a&&0===--X[a+1]&&(X[a]=void 0,pb.push(a))},rb=a=>{if(!a)throw new O("Cannot use deleted val. handle = "+a);return X[a]},U=a=>{switch(a){case void 0:return 2;case null:return 4;case !0:return 6;case !1:return 8;default:const b=pb.pop()||X.length;X[b]=a;X[b+1]=1;return b}},sb={name:"emscripten::val",fromWireType:a=>{var b=rb(a);qb(a);return b},toWireType:(a,b)=>U(b),ma:8,readValueFromPointer:G,na:null},tb=
(a,b,c)=>{switch(b){case 1:return c?function(d){return this.fromWireType(pa[d])}:function(d){return this.fromWireType(x[d])};case 2:return c?function(d){return this.fromWireType(y[d>>1])}:function(d){return this.fromWireType(A[d>>1])};case 4:return c?function(d){return this.fromWireType(B[d>>2])}:function(d){return this.fromWireType(D[d>>2])};default:throw new TypeError(`invalid integer width (${b}): ${a}`);}},ub=(a,b)=>{var c=I[a];if(void 0===c)throw a=`${b} has unknown type ${ib(a)}`,new O(a);return c},
Za=a=>{if(null===a)return"null";var b=typeof a;return"object"===b||"array"===b||"function"===b?a.toString():""+a},vb=(a,b)=>{switch(b){case 4:return function(c){return this.fromWireType(qa[c>>2])};case 8:return function(c){return this.fromWireType(ra[c>>3])};default:throw new TypeError(`invalid float width (${b}): ${a}`);}},wb=(a,b,c)=>{switch(b){case 1:return c?d=>pa[d]:d=>x[d];case 2:return c?d=>y[d>>1]:d=>A[d>>1];case 4:return c?d=>B[d>>2]:d=>D[d>>2];default:throw new TypeError(`invalid integer width (${b}): ${a}`);
}},xb="undefined"!=typeof TextDecoder?new TextDecoder:void 0,yb=(a,b,c)=>{var d=b+c;for(c=b;a[c]&&!(c>=d);)++c;if(16<c-b&&a.buffer&&xb)return xb.decode(a.subarray(b,c));for(d="";b<c;){var e=a[b++];if(e&128){var g=a[b++]&63;if(192==(e&224))d+=String.fromCharCode((e&31)<<6|g);else{var h=a[b++]&63;e=224==(e&240)?(e&15)<<12|g<<6|h:(e&7)<<18|g<<12|h<<6|a[b++]&63;65536>e?d+=String.fromCharCode(e):(e-=65536,d+=String.fromCharCode(55296|e>>10,56320|e&1023))}}else d+=String.fromCharCode(e)}return d},zb="undefined"!=
typeof TextDecoder?new TextDecoder("utf-16le"):void 0,Ab=(a,b)=>{var c=a>>1;for(var d=c+b/2;!(c>=d)&&A[c];)++c;c<<=1;if(32<c-a&&zb)return zb.decode(x.subarray(a,c));c="";for(d=0;!(d>=b/2);++d){var e=y[a+2*d>>1];if(0==e)break;c+=String.fromCharCode(e)}return c},Bb=(a,b,c)=>{c??=2147483647;if(2>c)return 0;c-=2;var d=b;c=c<2*a.length?c/2:a.length;for(var e=0;e<c;++e)y[b>>1]=a.charCodeAt(e),b+=2;y[b>>1]=0;return b-d},Cb=a=>2*a.length,Db=(a,b)=>{for(var c=0,d="";!(c>=b/4);){var e=B[a+4*c>>2];if(0==e)break;
++c;65536<=e?(e-=65536,d+=String.fromCharCode(55296|e>>10,56320|e&1023)):d+=String.fromCharCode(e)}return d},Eb=(a,b,c)=>{c??=2147483647;if(4>c)return 0;var d=b;c=d+c-4;for(var e=0;e<a.length;++e){var g=a.charCodeAt(e);if(55296<=g&&57343>=g){var h=a.charCodeAt(++e);g=65536+((g&1023)<<10)|h&1023}B[b>>2]=g;b+=4;if(b+4>c)break}B[b>>2]=0;return b-d},Fb=a=>{for(var b=0,c=0;c<a.length;++c){var d=a.charCodeAt(c);55296<=d&&57343>=d&&++c;b+=4}return b},Gb=[],Hb={},Ib=a=>{var b=Hb[a];return void 0===b?N(a):
b},Jb=()=>{function a(b){b.$$$embind_global$$$=b;var c="object"==typeof $$$embind_global$$$&&b.$$$embind_global$$$==b;c||delete b.$$$embind_global$$$;return c}if("object"==typeof globalThis)return globalThis;if("object"==typeof $$$embind_global$$$)return $$$embind_global$$$;"object"==typeof global&&a(global)?$$$embind_global$$$=global:"object"==typeof self&&a(self)&&($$$embind_global$$$=self);if("object"==typeof $$$embind_global$$$)return $$$embind_global$$$;throw Error("unable to get global object.");
},Kb=a=>{var b=Gb.length;Gb.push(a);return b},Lb=(a,b)=>{for(var c=Array(a),d=0;d<a;++d)c[d]=ub(D[b+4*d>>2],"parameter "+d);return c},Mb=Reflect.construct,Nb=[null,[],[]];J=n.InternalError=class extends Error{constructor(a){super(a);this.name="InternalError"}};for(var Ob=Array(256),Pb=0;256>Pb;++Pb)Ob[Pb]=String.fromCharCode(Pb);Ha=Ob;O=n.BindingError=class extends Error{constructor(a){super(a);this.name="BindingError"}};
Object.assign(Sa.prototype,{isAliasOf:function(a){if(!(this instanceof Sa&&a instanceof Sa))return!1;var b=this.fa.ia.ga,c=this.fa.ha;a.fa=a.fa;var d=a.fa.ia.ga;for(a=a.fa.ha;b.la;)c=b.va(c),b=b.la;for(;d.la;)a=d.va(a),d=d.la;return b===d&&c===a},clone:function(){this.fa.ha||Ka(this);if(this.fa.ta)return this.fa.count.value+=1,this;var a=S,b=Object,c=b.create,d=Object.getPrototypeOf(this),e=this.fa;a=a(c.call(b,d,{fa:{value:{count:e.count,ra:e.ra,ta:e.ta,ha:e.ha,ia:e.ia,ja:e.ja,pa:e.pa}}}));a.fa.count.value+=
1;a.fa.ra=!1;return a},["delete"](){this.fa.ha||Ka(this);if(this.fa.ra&&!this.fa.ta)throw new O("Object already scheduled for deletion");Ma(this);var a=this.fa;--a.count.value;0===a.count.value&&(a.ja?a.pa.oa(a.ja):a.ia.ga.oa(a.ha));this.fa.ta||(this.fa.ja=void 0,this.fa.ha=void 0)},isDeleted:function(){return!this.fa.ha},deleteLater:function(){this.fa.ha||Ka(this);if(this.fa.ra&&!this.fa.ta)throw new O("Object already scheduled for deletion");P.push(this);1===P.length&&Q&&Q(Pa);this.fa.ra=!0;return this}});
n.getInheritedInstanceCount=()=>Object.keys(R).length;n.getLiveInheritedInstances=()=>{var a=[],b;for(b in R)R.hasOwnProperty(b)&&a.push(R[b]);return a};n.flushPendingDeletes=Pa;n.setDelayFunction=a=>{Q=a;P.length&&Q&&Q(Pa)};
Object.assign(bb.prototype,{Pa(a){this.Ja&&(a=this.Ja(a));return a},Ha(a){this.oa?.(a)},ma:8,readValueFromPointer:G,fromWireType:function(a){function b(){return this.ya?Ra(this.ga.sa,{ia:this.Ra,ha:c,pa:this,ja:a}):Ra(this.ga.sa,{ia:this,ha:a})}var c=this.Pa(a);if(!c)return this.Ha(a),null;var d=Qa(this.ga,c);if(void 0!==d){if(0===d.fa.count.value)return d.fa.ha=c,d.fa.ja=a,d.clone();d=d.clone();this.Ha(a);return d}d=this.ga.Oa(c);d=Oa[d];if(!d)return b.call(this);d=this.xa?d.Ka:d.pointerType;var e=
Na(c,this.ga,d.ga);return null===e?b.call(this):this.ya?Ra(d.ga.sa,{ia:d,ha:e,pa:this,ja:a}):Ra(d.ga.sa,{ia:d,ha:e})}});gb=n.UnboundTypeError=((a,b)=>{var c=T(b,function(d){this.name=b;this.message=d;d=Error(d).stack;void 0!==d&&(this.stack=this.toString()+"\n"+d.replace(/^Error(:[^\n]*)?\n/,""))});c.prototype=Object.create(a.prototype);c.prototype.constructor=c;c.prototype.toString=function(){return void 0===this.message?this.name:`${this.name}: ${this.message}`};return c})(Error,"UnboundTypeError");
X.push(0,1,void 0,1,null,1,!0,1,!1,1);n.count_emval_handles=()=>X.length/2-5-pb.length;
var Rb={A:()=>{xa("")},u:a=>{var b=Da[a];delete Da[a];var c=b.elements,d=c.length,e=c.map(f=>f.Ba).concat(c.map(f=>f.Ea)),g=b.ua,h=b.oa;M([a],e,f=>{c.forEach((k,m)=>{var l=f[m],p=k.za,r=k.Aa,w=f[m+d],t=k.Da,v=k.Fa;k.read=C=>l.fromWireType(p(r,C));k.write=(C,K)=>{var z=[];t(v,C,w.toWireType(z,K));Ea(z)}});return[{name:b.name,fromWireType:k=>{for(var m=Array(d),l=0;l<d;++l)m[l]=c[l].read(k);h(k);return m},toWireType:(k,m)=>{if(d!==m.length)throw new TypeError(`Incorrect number of tuple elements for ${b.name}: expected=${d}, actual=${m.length}`);
for(var l=g(),p=0;p<d;++p)c[p].write(l,m[p]);null!==k&&k.push(h,l);return l},ma:8,readValueFromPointer:G,na:h}]})},m:a=>{var b=Ga[a];delete Ga[a];var c=b.ua,d=b.oa,e=b.Ia,g=e.map(h=>h.Ba).concat(e.map(h=>h.Ea));M([a],g,h=>{var f={};e.forEach((k,m)=>{var l=h[m],p=k.za,r=k.Aa,w=h[m+e.length],t=k.Da,v=k.Fa;f[k.Na]={read:C=>l.fromWireType(p(r,C)),write:(C,K)=>{var z=[];t(v,C,w.toWireType(z,K));Ea(z)}}});return[{name:b.name,fromWireType:k=>{var m={},l;for(l in f)m[l]=f[l].read(k);d(k);return m},toWireType:(k,
m)=>{for(var l in f)if(!(l in m))throw new TypeError(`Missing field: "${l}"`);var p=c();for(l in f)f[l].write(p,m[l]);null!==k&&k.push(d,p);return p},ma:8,readValueFromPointer:G,na:d}]})},x:()=>{},H:(a,b,c,d)=>{b=N(b);L(a,{name:b,fromWireType:function(e){return!!e},toWireType:function(e,g){return g?c:d},ma:8,readValueFromPointer:function(e){return this.fromWireType(x[e])},na:null})},j:(a,b,c,d,e,g,h,f,k,m,l,p,r)=>{l=N(l);g=V(e,g);f&&=V(h,f);m&&=V(k,m);r=V(p,r);var w=Va(l);Ua(w,function(){jb(`Cannot construct ${l} due to unbound types`,
[d])});M([a,b,c],d?[d]:[],t=>{t=t[0];if(d){var v=t.ga;var C=v.sa}else C=Sa.prototype;t=T(l,function(...Ja){if(Object.getPrototypeOf(this)!==K)throw new O("Use 'new' to construct "+l);if(void 0===z.qa)throw new O(l+" has no accessible constructor");var nb=z.qa[Ja.length];if(void 0===nb)throw new O(`Tried to invoke ctor of ${l} with invalid number of parameters (${Ja.length}) - expected (${Object.keys(z.qa).toString()}) parameters instead!`);return nb.apply(this,Ja)});var K=Object.create(C,{constructor:{value:t}});
t.prototype=K;var z=new Wa(l,t,K,r,v,g,f,m);if(z.la){var Z;(Z=z.la).Ga??(Z.Ga=[]);z.la.Ga.push(z)}v=new bb(l,z,!0,!1,!1);Z=new bb(l+"*",z,!1,!1,!1);C=new bb(l+" const*",z,!1,!0,!1);Oa[a]={pointerType:Z,Ka:C};cb(w,t);return[v,Z,C]})},h:(a,b,c,d,e,g)=>{var h=kb(b,c);e=V(d,e);M([],[a],f=>{f=f[0];var k=`constructor ${f.name}`;void 0===f.ga.qa&&(f.ga.qa=[]);if(void 0!==f.ga.qa[b-1])throw new O(`Cannot register multiple constructors with identical number of parameters (${b-1}) for class '${f.name}'! Overload resolution is currently only performed using the parameter count, not actual type info!`);
f.ga.qa[b-1]=()=>{jb(`Cannot construct ${f.name} due to unbound types`,h)};M([],h,m=>{m.splice(1,0,null);f.ga.qa[b-1]=mb(k,m,null,e,g);return[]});return[]})},a:(a,b,c,d,e,g,h,f)=>{var k=kb(c,d);b=N(b);b=ob(b);g=V(e,g);M([],[a],m=>{function l(){jb(`Cannot call ${p} due to unbound types`,k)}m=m[0];var p=`${m.name}.${b}`;b.startsWith("@@")&&(b=Symbol[b.substring(2)]);f&&m.ga.Sa.push(b);var r=m.ga.sa,w=r[b];void 0===w||void 0===w.ka&&w.className!==m.name&&w.wa===c-2?(l.wa=c-2,l.className=m.name,r[b]=
l):(Ta(r,b,p),r[b].ka[c-2]=l);M([],k,t=>{t=mb(p,t,m,g,h);void 0===r[b].ka?(t.wa=c-2,r[b]=t):r[b].ka[c-2]=t;return[]});return[]})},E:(a,b,c)=>{a=N(a);M([],[b],d=>{d=d[0];n[a]=d.fromWireType(c);return[]})},G:a=>L(a,sb),i:(a,b,c,d)=>{function e(){}b=N(b);e.values={};L(a,{name:b,constructor:e,fromWireType:function(g){return this.constructor.values[g]},toWireType:(g,h)=>h.value,ma:8,readValueFromPointer:tb(b,c,d),na:null});Ua(b,e)},c:(a,b,c)=>{var d=ub(a,"enum");b=N(b);a=d.constructor;d=Object.create(d.constructor.prototype,
{value:{value:c},constructor:{value:T(`${d.name}_${b}`,function(){})}});a.values[c]=d;a[b]=d},t:(a,b,c)=>{b=N(b);L(a,{name:b,fromWireType:d=>d,toWireType:(d,e)=>e,ma:8,readValueFromPointer:vb(b,c),na:null})},g:(a,b,c,d,e,g)=>{var h=kb(b,c);a=N(a);a=ob(a);e=V(d,e);Ua(a,function(){jb(`Cannot call ${a} due to unbound types`,h)},b-1);M([],h,f=>{f=[f[0],null].concat(f.slice(1));cb(a,mb(a,f,null,e,g),b-1);return[]})},f:(a,b,c,d,e)=>{b=N(b);-1===e&&(e=4294967295);e=f=>f;if(0===d){var g=32-8*c;e=f=>f<<g>>>
g}var h=b.includes("unsigned")?function(f,k){return k>>>0}:function(f,k){return k};L(a,{name:b,fromWireType:e,toWireType:h,ma:8,readValueFromPointer:wb(b,c,0!==d),na:null})},b:(a,b,c)=>{function d(g){return new e(pa.buffer,D[g+4>>2],D[g>>2])}var e=[Int8Array,Uint8Array,Int16Array,Uint16Array,Int32Array,Uint32Array,Float32Array,Float64Array][b];c=N(c);L(a,{name:c,fromWireType:d,ma:8,readValueFromPointer:d},{Qa:!0})},s:(a,b)=>{b=N(b);var c="std::string"===b;L(a,{name:b,fromWireType:function(d){var e=
D[d>>2],g=d+4;if(c)for(var h=g,f=0;f<=e;++f){var k=g+f;if(f==e||0==x[k]){h=h?yb(x,h,k-h):"";if(void 0===m)var m=h;else m+=String.fromCharCode(0),m+=h;h=k+1}}else{m=Array(e);for(f=0;f<e;++f)m[f]=String.fromCharCode(x[g+f]);m=m.join("")}W(d);return m},toWireType:function(d,e){e instanceof ArrayBuffer&&(e=new Uint8Array(e));var g,h="string"==typeof e;if(!(h||e instanceof Uint8Array||e instanceof Uint8ClampedArray||e instanceof Int8Array))throw new O("Cannot pass non-string to std::string");var f;if(c&&
h)for(g=f=0;g<e.length;++g){var k=e.charCodeAt(g);127>=k?f++:2047>=k?f+=2:55296<=k&&57343>=k?(f+=4,++g):f+=3}else f=e.length;g=f;f=Qb(4+g+1);k=f+4;D[f>>2]=g;if(c&&h){if(h=k,k=g+1,g=x,0<k){k=h+k-1;for(var m=0;m<e.length;++m){var l=e.charCodeAt(m);if(55296<=l&&57343>=l){var p=e.charCodeAt(++m);l=65536+((l&1023)<<10)|p&1023}if(127>=l){if(h>=k)break;g[h++]=l}else{if(2047>=l){if(h+1>=k)break;g[h++]=192|l>>6}else{if(65535>=l){if(h+2>=k)break;g[h++]=224|l>>12}else{if(h+3>=k)break;g[h++]=240|l>>18;g[h++]=
128|l>>12&63}g[h++]=128|l>>6&63}g[h++]=128|l&63}}g[h]=0}}else if(h)for(h=0;h<g;++h){m=e.charCodeAt(h);if(255<m)throw W(k),new O("String has UTF-16 code units that do not fit in 8 bits");x[k+h]=m}else for(h=0;h<g;++h)x[k+h]=e[h];null!==d&&d.push(W,f);return f},ma:8,readValueFromPointer:G,na(d){W(d)}})},l:(a,b,c)=>{c=N(c);if(2===b){var d=Ab;var e=Bb;var g=Cb;var h=f=>A[f>>1]}else 4===b&&(d=Db,e=Eb,g=Fb,h=f=>D[f>>2]);L(a,{name:c,fromWireType:f=>{for(var k=D[f>>2],m,l=f+4,p=0;p<=k;++p){var r=f+4+p*b;
if(p==k||0==h(r))l=d(l,r-l),void 0===m?m=l:(m+=String.fromCharCode(0),m+=l),l=r+b}W(f);return m},toWireType:(f,k)=>{if("string"!=typeof k)throw new O(`Cannot pass non-string to C++ string type ${c}`);var m=g(k),l=Qb(4+m+b);D[l>>2]=m/b;e(k,l+4,m+b);null!==f&&f.push(W,l);return l},ma:8,readValueFromPointer:G,na(f){W(f)}})},q:(a,b,c,d,e,g)=>{Da[a]={name:N(b),ua:V(c,d),oa:V(e,g),elements:[]}},D:(a,b,c,d,e,g,h,f,k)=>{Da[a].elements.push({Ba:b,za:V(c,d),Aa:e,Ea:g,Da:V(h,f),Fa:k})},r:(a,b,c,d,e,g)=>{Ga[a]=
{name:N(b),ua:V(c,d),oa:V(e,g),Ia:[]}},k:(a,b,c,d,e,g,h,f,k,m)=>{Ga[a].Ia.push({Na:N(b),Ba:c,za:V(d,e),Aa:g,Ea:h,Da:V(f,k),Fa:m})},I:(a,b)=>{b=N(b);L(a,{Va:!0,name:b,ma:0,fromWireType:()=>{},toWireType:()=>{}})},o:(a,b,c,d)=>{a=Gb[a];b=rb(b);return a(null,b,c,d)},e:(a,b,c,d,e)=>{a=Gb[a];b=rb(b);c=Ib(c);return a(b,b[c],d,e)},J:qb,v:a=>{if(0===a)return U(Jb());a=Ib(a);return U(Jb()[a])},d:(a,b,c)=>{var d=Lb(a,b),e=d.shift();a--;var g=Array(a);b=`methodCaller<(${d.map(h=>h.name).join(", ")}) => ${e.name}>`;
return Kb(T(b,(h,f,k,m)=>{for(var l=0,p=0;p<a;++p)g[p]=d[p].readValueFromPointer(m+l),l+=d[p].ma;f=1===c?Mb(f,g):f.apply(h,g);h=[];f=e.toWireType(h,f);h.length&&(D[k>>2]=U(h));return f}))},n:a=>{9<a&&(X[a+1]+=1)},K:()=>U([]),C:a=>U(Ib(a)),B:a=>{var b=rb(a);Ea(b);qb(a)},F:(a,b)=>{a=ub(a,"_emval_take_value");a=a.readValueFromPointer(b);return U(a)},y:a=>{var b=x.length;a>>>=0;if(2147483648<a)return!1;for(var c=1;4>=c;c*=2){var d=b*(1+.2/c);d=Math.min(d,a+100663296);a:{d=(Math.min(2147483648,65536*Math.ceil(Math.max(a,
d)/65536))-na.buffer.byteLength+65535)/65536;try{na.grow(d);sa();var e=1;break a}catch(g){}e=void 0}if(e)return!0}return!1},z:()=>52,w:function(){return 70},p:(a,b,c,d)=>{for(var e=0,g=0;g<c;g++){var h=D[b>>2],f=D[b+4>>2];b+=8;for(var k=0;k<f;k++){var m=x[h+k],l=Nb[a];0===m||10===m?((1===a?ma:u)(yb(l,0)),l.length=0):l.push(m)}e+=f}D[d>>2]=e;return 0}},Y=function(){var a={a:Rb};E++;za??=ya("pathkit.wasm")?"pathkit.wasm":n.locateFile?n.locateFile("pathkit.wasm",q):q+"pathkit.wasm";Ca(a,function(b){Y=
b.instance.exports;na=Y.L;sa();db=Y.ba;ua.unshift(Y.M);E--;0==E&&(null!==wa&&(clearInterval(wa),wa=null),F&&(b=F,F=null,b()))}).catch(ba);return{}}(),hb=a=>(hb=Y.N)(a);n.__Z6ToCmdsRK6SkPath=(a,b)=>(n.__Z6ToCmdsRK6SkPath=Y.O)(a,b);n.__Z8FromCmdsmi=(a,b,c)=>(n.__Z8FromCmdsmi=Y.P)(a,b,c);n.__Z7NewPathv=a=>(n.__Z7NewPathv=Y.Q)(a);n.__Z8CopyPathRK6SkPath=(a,b)=>(n.__Z8CopyPathRK6SkPath=Y.R)(a,b);n.__Z6EqualsRK6SkPathS1_=(a,b)=>(n.__Z6EqualsRK6SkPathS1_=Y.S)(a,b);
n.__Z7IsEmptyRK6SkPath=a=>(n.__Z7IsEmptyRK6SkPath=Y.T)(a);n.__Z11ToSVGStringRK6SkPath=(a,b)=>(n.__Z11ToSVGStringRK6SkPath=Y.U)(a,b);n.__Z13FromSVGStringNSt3__212basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEEE=(a,b)=>(n.__Z13FromSVGStringNSt3__212basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEEE=Y.V)(a,b);n.__Z13ApplySimplifyR6SkPath=a=>(n.__Z13ApplySimplifyR6SkPath=Y.W)(a);n.__Z14ApplyAsWindingR6SkPath=a=>(n.__Z14ApplyAsWindingR6SkPath=Y.X)(a);
n.__Z11ApplyPathOpR6SkPathRKS_8SkPathOp=(a,b,c)=>(n.__Z11ApplyPathOpR6SkPathRKS_8SkPathOp=Y.Y)(a,b,c);n.__Z10MakeFromOpRK6SkPathS1_8SkPathOp=(a,b,c,d)=>(n.__Z10MakeFromOpRK6SkPathS1_8SkPathOp=Y.Z)(a,b,c,d);n.__Z14ResolveBuilderR11SkOpBuilder=(a,b)=>(n.__Z14ResolveBuilderR11SkOpBuilder=Y._)(a,b);n.__Z8ToCanvasRK6SkPathN10emscripten3valE=(a,b)=>(n.__Z8ToCanvasRK6SkPathN10emscripten3valE=Y.$)(a,b);n.__Z8ToPath2DRK6SkPath=(a,b)=>(n.__Z8ToPath2DRK6SkPath=Y.aa)(a,b);
var W=n._free=a=>(W=n._free=Y.ca)(a),Qb=n._malloc=a=>(Qb=n._malloc=Y.da)(a);n.dynCall_jiji=(a,b,c,d,e)=>(n.dynCall_jiji=Y.ea)(a,b,c,d,e);var Sb;F=function Tb(){Sb||Ub();Sb||(F=Tb)};function Ub(){if(!(0<E)){for(;0<ta.length;)ta.shift()(n);if(!(0<E||Sb||(Sb=!0,n.calledRun=!0,oa))){for(;0<ua.length;)ua.shift()(n);aa(n);for(n.onRuntimeInitialized?.();0<va.length;)va.shift()(n)}}}Ub();moduleRtn=ca;


  return moduleRtn;
}
);
})();
export default PathKitInit;