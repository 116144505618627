import { LogLevel, LogMessage } from "../log-manager";
import { makeCachedReducer } from "./reducer-cache";
import { ExpressionTrace, Trace } from "./trace";

export const traceAllMessages = makeCachedReducer("allMessages", (trace: Trace): LogMessage[] => {
  const messages: LogMessage[] = [];
  if (trace instanceof ExpressionTrace) {
    messages.push(...trace.messages);
  } else {
    for (const childTrace of trace.childTraces()) {
      const childMessages = traceAllMessages(childTrace);
      messages.push(...childMessages);
    }
  }
  return messages;
});

export const traceUniqueMessages = makeCachedReducer(
  "uniqueMessages",
  (trace: Trace): LogMessage[] => {
    const messages: LogMessage[] = [];
    const messageStringsByLogLevel: Partial<Record<LogLevel, Set<string>>> = {};
    const addUnique = (message: LogMessage) => {
      const messageString = message.toString();
      let messageStrings = messageStringsByLogLevel[message.logLevel];
      if (!messageStrings) {
        messageStrings = new Set();
        messageStringsByLogLevel[message.logLevel] = messageStrings;
      }
      if (!messageStrings.has(messageString)) {
        messageStrings.add(messageString);
        messages.push(message);
      }
    };
    traceAllMessages(trace).forEach(addUnique);
    return messages;
  }
);
