import m from "mithril";
import { isString } from "../../geom";
import { scrollIntoView } from "../util";
import { Icon20 } from "../../shared/icon";
import { domForVnode } from "../../shared/util";

export type SelectListOption = {
  label: string;
  keywords?: string;
  value: string;
  render?: () => m.Children;
};

interface SelectListGroupWithQueryAttrs {
  groups: GroupAttrs[];
  /** The primary group will be focused on create, and with arrow navigation. */
  primaryGroupKey: string;
  onQuery: (query: string) => void;
  headerExtra?: () => m.Children;
}
export const SelectListGroupWithQuery: m.ClosureComponent<SelectListGroupWithQueryAttrs> = (
  vnode
) => {
  let latestVnode = vnode;
  let query = "";

  const onKeyDownFocusItem = (event: KeyboardEvent) => {
    const queryEl = event.target as HTMLInputElement;
    const { selectionStart, selectionEnd, value } = queryEl;
    // Arrow down or enter, and cursor is at end of input
    if (
      (event.code === "ArrowDown" || event.code === "Enter") &&
      selectionStart === value.length &&
      selectionEnd === value.length
    ) {
      const { primaryGroupKey } = latestVnode.attrs;
      const list = domForVnode(latestVnode).querySelector(
        `.select-list[data-key="${primaryGroupKey}"]`
      );
      focusSelectedOrClickFirstItemInList(event, list);
    }
  };

  const onInputQuery = (event: Event) => {
    const queryEl = event.target as HTMLInputElement;
    query = queryEl.value;
    latestVnode.attrs.onQuery(query);
  };

  const onKeyDownFocusQuery = (event: KeyboardEvent) => {
    const isLetterOrNumber = /^[a-z0-9]$/i.test(event.key);
    if (!isLetterOrNumber) return;
    if (event.metaKey || event.ctrlKey || event.altKey) return;

    const dom = domForVnode(latestVnode);
    const queryEl = dom.querySelector(".query") as HTMLInputElement;
    if (document.activeElement !== queryEl) {
      queryEl.focus();
      queryEl.setSelectionRange(0, queryEl.value.length);
    }
  };

  return {
    view(vnode) {
      latestVnode = vnode;
      const { groups, headerExtra, primaryGroupKey } = latestVnode.attrs;

      return m(
        ".select-list-group-with-query",
        {
          onkeydown: onKeyDownFocusQuery,
        },
        [
          m(
            ".select-list-group-with-query-header",
            m("label.query-label", { "aria-label": "Search" }, [
              m(Icon20, { icon: "search" }),
              m("input.query", {
                type: "text",
                oninput: onInputQuery,
                onkeydown: onKeyDownFocusItem,
                value: query,
              }),
            ]),
            headerExtra && headerExtra()
          ),
          m(SelectListGroup, {
            groups,
            primaryGroupKey,
          }),
        ]
      );
    },
  };
};

interface SelectListGroupAttrs {
  groups: GroupAttrs[];
  /** The primary group will be focused on create. */
  primaryGroupKey: string;
}
const SelectListGroup: m.Component<SelectListGroupAttrs> = {
  view({ attrs: { groups } }) {
    // If arrow keys presses are not stopped by items, see if they apply to
    // navigating between lists.
    const onKeyArrowList = (event: KeyboardEvent) => {
      const { code } = event;
      const targetListEl = event.target as HTMLElement;

      let nextListEl: undefined | null | Element;

      if (code === "ArrowLeft") {
        nextListEl = targetListEl.parentElement?.previousElementSibling;
      }
      if (code === "ArrowRight") {
        nextListEl = targetListEl.parentElement?.nextElementSibling;
      }
      focusSelectedOrClickFirstItemInList(event, nextListEl);
    };

    return m(".select-list-group", [
      groups.map((group) =>
        typeof group === "function"
          ? group()
          : m(SelectList, {
              onKeyArrowList,
              ...group,
            })
      ),
    ]);
  },
  oncreate({ attrs: { primaryGroupKey }, dom }) {
    // Only "autofocus" once, on creation of the group.
    const primarySelectedEl = dom.querySelector(
      `.select-list[data-key="${primaryGroupKey}"] .item[aria-selected="true"]`
    );
    if (primarySelectedEl) {
      (primarySelectedEl as HTMLElement).focus();
    }
  },
};

interface SelectListAttrs {
  key: string;
  className?: string;
  options: Array<string | SelectListOption>;
  value?: string;
  onSelect: (label: string) => void;
  itemClassName?: string;
  /** Must be manually synced with list styling. Default is 1 for a standard
   * vertical list. */
  columnCount?: number;
  onKeyArrowList: (event: KeyboardEvent) => void;
}

export type GroupAttrs = Omit<SelectListAttrs, "onKeyArrowList"> | (() => m.Child);

const SelectList: m.Component<SelectListAttrs> = {
  view({
    attrs: {
      key,
      className,
      options,
      value,
      onSelect,
      onKeyArrowList,
      itemClassName,
      columnCount = 1,
    },
  }) {
    // Item select within the list. Stops propagation to parent list only if a
    // list item is in that arrow direction. Defined here so that items don't
    // need to know about the list's column count.
    const onKeyArrowItem = (event: KeyboardEvent) => {
      const { code } = event;
      const targetItemEl = event.target as HTMLElement;

      const index = parseInt(targetItemEl.getAttribute("data-index") || "-1", 10);
      if (index < 0) return;

      const row = Math.floor(index / columnCount);
      const column = index % columnCount;

      let nextEl: HTMLElement | null | undefined;

      if (code === "ArrowLeft" && column > 0) {
        const nextIndex = index - 1;
        nextEl = targetItemEl.parentElement?.querySelector(`[data-index="${nextIndex}"]`);
      }
      if (code === "ArrowRight" && column < columnCount - 1) {
        const nextIndex = index + 1;
        nextEl = targetItemEl.parentElement?.querySelector(`[data-index="${nextIndex}"]`);
      }
      if (code === "ArrowUp" && row > 0) {
        const prevIndex = index - columnCount;
        nextEl = targetItemEl.parentElement?.querySelector(`[data-index="${prevIndex}"]`);
      }
      if (code === "ArrowDown") {
        const nextIndex = index + columnCount;
        nextEl = targetItemEl.parentElement?.querySelector(`[data-index="${nextIndex}"]`);
      }
      if (nextEl) {
        event.preventDefault();
        event.stopPropagation();
        (nextEl as HTMLElement).focus();
        (nextEl as HTMLElement).click();
      }
    };

    const items: m.Children = options.map((option, index) => {
      const optionLabel = isString(option) ? option : option.label;
      const optionValue = isString(option) ? option : option.value;
      const itemChildren =
        isString(option) || !option.render ? m(".label", optionLabel) : option.render();
      const selected = optionValue === value;
      return m(
        SelectListItem,
        {
          key: optionValue,
          value: optionValue,
          index,
          selected,
          onSelect: () => onSelect(optionValue),
          onKeyArrowItem,
          itemClassName,
        },
        itemChildren
      );
    });
    return m(
      ".select-list.scrollable",
      {
        role: "listbox",
        className,
        "data-key": key,
        tabIndex: -1,
        "aria-activedescendant": value,
        onkeydown: onKeyArrowList,
      },
      items
    );
  },
};

interface SelectListItemAttrs {
  value: string;
  index: number;
  selected: boolean;
  onSelect: () => void;
  onKeyArrowItem: (event: KeyboardEvent) => void;
  itemClassName?: string;
}
const SelectListItem: m.Component<SelectListItemAttrs> = {
  view({ attrs: { value, index, selected, onSelect, onKeyArrowItem, itemClassName }, children }) {
    return m(
      ".item" + (itemClassName ? `.${itemClassName}` : ""),
      {
        id: value,
        "data-value": value,
        "data-index": index,
        role: "option",
        "aria-selected": String(selected),
        // Selected item of each list can be tabbed to, then arrows to select
        // items or switch focused list
        tabIndex: selected ? 0 : -1,
        onclick: onSelect,
        onkeydown: onKeyArrowItem,
      },
      children
    );
  },
  oncreate({ attrs: { selected }, dom }) {
    if (selected) {
      scrollIntoView(dom as HTMLElement);
    }
  },
};

function focusSelectedOrClickFirstItemInList(event: Event, listEl: Element | null | undefined) {
  if (!listEl) return;

  const selectedItemEl = listEl.querySelector(".item[aria-selected='true']");
  if (selectedItemEl) {
    event.preventDefault();
    event.stopPropagation();
    (selectedItemEl as HTMLElement).focus();
    return;
  }

  const firstItemEl = listEl.querySelector(".item");
  if (firstItemEl) {
    event.preventDefault();
    event.stopPropagation();
    (firstItemEl as HTMLElement).focus();
    (firstItemEl as HTMLElement).click();
    return;
  }
}
