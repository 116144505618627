import {
  BoundingBox,
  CompoundPath,
  Containment,
  FontGlyph,
  Graphic,
  Group,
  isNumber,
  PathContainment,
  Vec,
} from "..";

export interface ConnectOptions {
  /**
   * The baseline is used to find the main contour in the glyph. Defaults to
   * zero.
   */
  baseline?: number;

  /**
   * The amount to thicken the glyph before connecting. Defaults to zero.
   */
  thicken?: number;

  /**
   * The minimum distance for the shapes to overlap.
   */
  minOverlap?: number;

  /**
   * When connecting, move this distance in addition to the distance required to
   * satisfy `minOverlap`.
   */
  scrunch?: number;
}

export interface ConnectToLineOptions {
  /**
   * If true, glyphs that are already connected will be connected according to
   * `minOverlap`. Normally Cuttle will leave initially connected glyphs
   * unchanged. Many script fonts desgin their glyphs to connect in a specific
   * way, and we want to keep that. Use this option when connecting a glyph from
   * a different font or symbol.
   */
  ignoreInitialConnection?: boolean;

  /**
   * The minimum distance for the shapes to overlap.
   */
  minOverlap?: number;

  /**
   * When connecting, move this distance in addition to the distance required to
   * satisfy `minOverlap`.
   */
  scrunch?: number;

  /**
   * @internal
   */
  showDebug?: boolean;
}

// The minimum width of the connections.
const minOverlapChars = 0.03;
const minOverlapDots = 0.02;

// Extra displacement to make sure glyphs are well connected.
const scrunchChars = 0.025;
const scrunchDots = 0.01;

const isTooComplicated = (geom: Graphic) => {
  if (geom instanceof CompoundPath) {
    return geom.paths.length > 10;
  }
  if (geom instanceof Group) {
    return geom.items.length > 10;
  }
  return false;
};

const closestContainmentToBaseline = (containments: PathContainment[], baseline: number) => {
  let closest = containments[0];
  let closestDistance = Infinity;
  for (let i = 0; i < containments.length; ++i) {
    const c = containments[i];
    if (c.box === undefined) continue;
    const distance = boxDistanceToBaseline(c.box, baseline);
    if (distance < closestDistance) {
      closest = c;
      closestDistance = distance;
    }
  }
  return closest;
};

const boxDistanceToBaseline = (box: BoundingBox, baseline: number) => {
  return Math.min(Math.abs(box.min.y - baseline), Math.abs(box.max.y - baseline));
};

const expand = (path: CompoundPath, distance: number) => {
  return CompoundPath.booleanUnion([
    path,
    CompoundPath.stroke(path, { width: distance * 2, join: "round" }),
  ]).copyStyle(path);
};

// This will move any dots or accents down until they intersect the main path.
export const connectGlyph = (glyph: FontGlyph, options?: ConnectOptions) => {
  if (glyph.isBlank() || isTooComplicated(glyph.geometry)) return;

  const baseline = options?.baseline ?? 0;
  const thicken = options?.thicken ?? 0;
  const minOverlap = options?.minOverlap ?? minOverlapDots;
  const scrunch = options?.scrunch ?? scrunchDots;

  const containment = Containment.fromPaths(glyph.geometry.allPaths());
  const mainContainment = closestContainmentToBaseline(containment.contained, baseline);
  let mainCompoundPath = new CompoundPath(mainContainment.allPaths());

  if (thicken > 0) {
    mainCompoundPath = expand(mainCompoundPath, thicken);
  }

  const compoundPaths = [mainCompoundPath];

  for (const c of containment.contained) {
    if (c === mainContainment) continue;
    let dotPath = new CompoundPath(c.allPaths());
    if (thicken > 0) {
      dotPath = expand(dotPath, thicken);
    }
    if (!c.box) return;
    const dotCenter = c.box.center();
    const closestPoint = mainCompoundPath.closestPoint(dotCenter);
    if (!closestPoint) return;
    const direction = closestPoint.position.clone().sub(dotCenter).normalize();
    const distance = dotPath.distanceToIntersect(mainCompoundPath, direction, { minOverlap });
    if (isNumber(distance) && distance > 0) {
      const displacement = direction.clone().mulScalar(distance + scrunch);
      dotPath.transform({ position: displacement });
    }
    compoundPaths.push(dotPath);
  }

  glyph.geometry = new Group(compoundPaths);
};

const displacementForClosestPointConnection = (
  geom: Graphic,
  targetGeom: Graphic,
  minOverlap: number,
  scrunch: number
) => {
  const geomCenter = geom.boundingBox()?.center();
  if (!geomCenter) return;

  const closestPoint = targetGeom.closestPoint(geomCenter);
  if (!closestPoint) return;

  const direction = closestPoint.position.clone().sub(geomCenter).normalize();
  const distance = geom.distanceToIntersect(targetGeom, direction, { minOverlap });
  if (isNumber(distance) && distance > 0) {
    const displacement = direction.clone().mulScalar(distance + scrunch);
    return displacement;
  }
};

const lastFewNonBlankGlyphs = (glyphs: FontGlyph[], count = 4) => {
  // Omit any spaces and glyphs before spaces.
  let startIndex = Math.max(0, glyphs.length - count);
  for (let i = glyphs.length; --i >= startIndex; ) {
    if (glyphs[i].isBlank()) {
      startIndex = i + 1;
      break;
    }
  }
  return glyphs.slice(startIndex);
};

// Modifies the previous glyph's advanceX so that 'glyph' will be connected.
// Returns the connected glyph geometry.
export const connectGlyphToLine = (
  glyph: FontGlyph,
  lineGlyphs: FontGlyph[],
  options?: ConnectToLineOptions
) => {
  let glyphGeom = glyph.geometry;

  // Skip degenerate cases.
  if (lineGlyphs.length < 1) return;
  if (glyph.isBlank()) return;

  const lastFewGlyphs = lastFewNonBlankGlyphs(lineGlyphs);
  if (lastFewGlyphs.length < 1) return;

  // Transform glyph geometries into place according to their advanceXs.
  let advanceX = 0;
  const lastFewGeoms = new Group(
    lastFewGlyphs.map((glyph) => {
      const geom = glyph.geometry.clone().transform({ position: new Vec(advanceX, 0) });
      advanceX += glyph.advanceX;
      return geom;
    })
  );

  // Transform the last glyph into place.
  glyphGeom = glyphGeom.clone().transform({ position: new Vec(advanceX, 0) });

  // If the previous glyph is a space, don't try to connect.
  const prevGlyph = lineGlyphs[lineGlyphs.length - 1];
  if (prevGlyph.isBlank()) return;

  // logManager.consoleGeometry(glyphGeom.clone(), lastFewGeoms.clone());

  const ignoreInitialConnection = options?.ignoreInitialConnection ?? false;
  const minOverlap = options?.minOverlap ?? minOverlapChars;
  const scrunch = options?.scrunch ?? scrunchChars;
  const showDebug = options?.showDebug ?? false;

  // Try to find a connection with the line by moving to the left.
  const direction = new Vec(-1, 0);
  const distance = glyphGeom.distanceToIntersect(lastFewGeoms, direction, {
    minOverlap,
    showDebug,
  });
  if (distance === undefined) return;

  // Glyphs are already touching.
  let isConnected = distance <= 0;

  if (!isConnected && !ignoreInitialConnection) {
    // Perform an intersection to test if the glyphs are already connected by
    // the min overlap. This will catch pairs of glyps that have a thin
    // connection in only the y dimension.
    const lastGeom = lastFewGeoms.items.slice(-1);
    const xs = glyphGeom.intersectionsWith(lastGeom);
    if (xs.length > 2) isConnected = true;
    if (xs.length === 2) {
      if (xs[0].position.distance(xs[1].position) >= minOverlap) {
        isConnected = true;
      }
    }
  }

  // Don't allow symbols to be incidentally connected if they already overlap.
  if (ignoreInitialConnection) isConnected = false;

  // The glyphs already intersect.
  if (isConnected) return;

  const prevGeom = lastFewGeoms.items[lastFewGeoms.items.length - 1];
  const prevBox = prevGeom.boundingBox();
  const prevToBaseline = prevBox ? boxDistanceToBaseline(prevBox, 0) : Infinity;
  const glyphBox = glyphGeom.boundingBox();
  const glyphToBaseline = glyphBox ? boxDistanceToBaseline(glyphBox, 0) : Infinity;

  // Move to the left if the distance isn't too far.
  if (!isConnected && typeof distance === "number") {
    const displacement = direction.clone().mulScalar(distance + scrunch);
    if (
      // If we're moving a glyph larger than the previous one, then don't worry
      // about moving too far. Smaller glyphs like punctuation can be moved past
      // easily.
      prevGlyph.advanceX < glyph.advanceX ||
      // Prevent moving too far past a previous larger glyph. This prevents
      // punctuation like quotes moving past the previous glyph because it was
      // too low.
      prevGlyph.advanceX * 0.75 > -displacement.x ||
      glyphToBaseline < prevToBaseline
    ) {
      // Looks good. Commit the connection.
      prevGlyph.advanceX += displacement.x;
      glyphGeom.transform({ position: displacement });
      isConnected = true;
    } else {
      // We moved past the previous glyph.
    }
  }

  // Connect to the closest point on the previous glyph instead.
  if (!isConnected) {
    // Sometimes we need to move the previous glyph to touch this one. Such as
    // when a string starts with a quotation mark, like '"e'.
    const isSecondGlyph = lineGlyphs.length === 1 || lineGlyphs[lineGlyphs.length - 2].isBlank();
    const isMovePrev = isSecondGlyph && glyphToBaseline < prevToBaseline;

    if (isMovePrev) {
      // Move the previous glyph to touch the current one.
      const displacement = displacementForClosestPointConnection(
        prevGeom,
        glyphGeom,
        minOverlap,
        scrunch
      );
      if (displacement) {
        // Move the current glyph to the left to meet the previous one.
        glyphGeom.transform({ position: new Vec(-displacement.x, 0) });

        // Move the previous glyph down and shorten its advance width by the
        // amount we moved the current glyph.
        prevGlyph.advanceX -= displacement.x;
        prevGlyph.geometry.transform({
          position: new Vec(0, displacement.y),
        });
      }
    } else {
      const displacement = displacementForClosestPointConnection(
        glyphGeom,
        prevGeom,
        minOverlap,
        scrunch
      );
      if (displacement) {
        prevGlyph.advanceX += displacement.x;
        // Zero out the current glyph's advance width if it looks like a quote.
        if (glyphToBaseline > prevToBaseline) {
          glyph.advanceX = -displacement.x;
        }

        glyphGeom.transform({ position: displacement });

        // Modify the glyph's geometry to account for the Y displacement. X
        // displacement is handled by advanceX.
        glyph.geometry.transform({ position: new Vec(0, displacement.y) });
      }
    }
  }
};
