import { AffineMatrix, Axis, MINIMUM_TOLERANCE, Vec, assert } from "../../geom";
import { CanvasDrag, globalState } from "../../global-state";
import { CanvasPoint } from "../../model/canvas-point";
import { SelectableNode } from "../../model/selectable";
import { Selection } from "../../model/selection";
import { SelectionTransformer } from "../../model/transformer";
import { minimumPrecisionWorldPositionsFromCanvasDrag } from "../util";
import { startCanvasDrag } from "./canvas-drag";

export const startSelectionDrag = (
  downEvent: PointerEvent,
  selection: Selection,
  onCancel?: () => void
) => {
  let transformer: SelectionTransformer;
  let canDuplicate: boolean;

  startCanvasDrag(downEvent, {
    onConsummate(moveEvent, canvasDrag) {
      transformer = new SelectionTransformer(selection);
      canDuplicate = selection.items.every((item) => item instanceof SelectableNode);
    },
    onMove(moveEvent, canvasDrag) {
      const { startPosition, currentPosition } =
        minimumPrecisionWorldPositionsFromCanvasDrag(canvasDrag);
      const matrix = AffineMatrix.fromTranslationPoints(startPosition, currentPosition);
      const handleConstraint = moveEvent.altKey ? "free" : undefined;
      transformer.transformWorld(matrix, {
        overrideAnchorHandleConstraint: handleConstraint,
        assignAnchorHandleConstraint: handleConstraint,
      });
    },
    onUp(upEvent) {
      if (canDuplicate && upEvent.altKey) {
        // Duplicate the dragging nodes and move the originals back into place
        globalState.project.duplicateSelection();
        transformer.revert();
      }
    },
    onCancel,
    cursor() {
      if (globalState.isAltDown) {
        if (canDuplicate) return "duplicate";
        return "select-split-handles";
      }
      return "select";
    },
  });
};

const trySetOverrideTransformCenter = () => {
  const { project } = globalState;
  if (!project.hasOverrideTransformCenter()) {
    const transformCenter = project.transformCenter();
    if (transformCenter) {
      project.setOverrideTransformCenter(transformCenter.clone());
      return true;
    }
  }
  return false;
};

export const startSelectionTransformDrag = (
  downEvent: PointerEvent,
  selection: Selection,
  allowRotate: boolean,
  allowScale: boolean,
  uniformScale: boolean
) => {
  let transformer: SelectionTransformer;

  const isOverrideTransformCenterSet = trySetOverrideTransformCenter();
  const transformCenter = globalState.project.transformCenter() ?? new CanvasPoint(new Vec());

  startCanvasDrag(downEvent, {
    rotationTransformCenter: transformCenter.worldPosition,
    onConsummate(event: PointerEvent, canvasDrag: CanvasDrag) {
      transformer = new SelectionTransformer(selection);
    },
    onMove(event: PointerEvent, canvasDrag: CanvasDrag) {
      const { startPosition, currentPosition } =
        minimumPrecisionWorldPositionsFromCanvasDrag(canvasDrag);
      const allowRotationNow = allowRotate || event.ctrlKey || event.metaKey;
      const rotationIncrement = rotationIncrementForCanvasDragEvent(canvasDrag, event);

      const transformMatrix = AffineMatrix.fromCenterAndReferencePoints(
        transformCenter.worldPosition,
        startPosition,
        currentPosition,
        {
          allowRotation: allowRotationNow,
          allowScale: allowScale ? (uniformScale ? "uniform" : true) : false,
          rotationIncrement,
        }
      );

      transformer.transformWorld(transformMatrix, {
        preserveRotation: uniformScale && !allowRotationNow,
      });
    },
    onUp() {
      if (isOverrideTransformCenterSet) {
        globalState.project.clearOverrideTransformCenter();
      }
    },
  });
};

export const startSelectionTransformBoxDrag = (
  downEvent: PointerEvent,
  selection: Selection,
  boundingBoxTransform: AffineMatrix,
  boundingBoxOriginPosition: Vec,
  boundingBoxHandlePosition: Vec,
  isSide: boolean,
  forceCentered: boolean
) => {
  let transformer: SelectionTransformer;
  let inverseBoundingBoxTransform: AffineMatrix;
  let isOverrideTransformCenterSet = false;

  const handleWorldPosition = boundingBoxHandlePosition
    .clone()
    .affineTransform(boundingBoxTransform);

  startCanvasDrag(downEvent, {
    disableDisplay: true,
    startWorldPosition: handleWorldPosition,
    onConsummate(event: PointerEvent, canvasDrag: CanvasDrag) {
      transformer = new SelectionTransformer(selection);
      inverseBoundingBoxTransform = boundingBoxTransform.clone().invert();
      isOverrideTransformCenterSet = trySetOverrideTransformCenter();
    },
    onMove(event: PointerEvent, canvasDrag: CanvasDrag) {
      const { currentPosition } = minimumPrecisionWorldPositionsFromCanvasDrag(canvasDrag);
      const boxCurrentPosition = currentPosition
        .clone()
        .affineTransform(inverseBoundingBoxTransform);

      const isUniform = event.shiftKey || (!isSide && selection.isUniformScale());

      let transformCenter: CanvasPoint | undefined;
      if (forceCentered || event.ctrlKey || event.metaKey) {
        transformCenter = globalState.project.transformCenter();
      }

      let transformMatrix: AffineMatrix;
      if (isSide) {
        let scaleOrigin = boundingBoxOriginPosition;
        if (transformCenter) {
          const transformCenterOrigin = transformCenter.worldPosition
            .clone()
            .affineTransform(inverseBoundingBoxTransform);
          if (!isUniform) {
            const axis = new Axis(
              boundingBoxOriginPosition,
              boundingBoxHandlePosition.clone().sub(boundingBoxOriginPosition)
            );
            const result = axis.closestPoint(transformCenterOrigin);
            assert(result); // Should only be undefined if areaOfInterest is specified
            transformCenterOrigin.copy(result.position);
          }
          if (transformCenterOrigin.distance(boundingBoxHandlePosition) > MINIMUM_TOLERANCE) {
            scaleOrigin = transformCenterOrigin;
          }
        }
        transformMatrix = AffineMatrix.fromCenterAndReferencePoints(
          scaleOrigin,
          boundingBoxHandlePosition,
          boxCurrentPosition,
          { allowScale: isUniform ? "uniform" : true }
        );
      } else {
        let boxOriginPos = boundingBoxOriginPosition;
        if (transformCenter) {
          const centeredOriginPos = transformCenter.worldPosition
            .clone()
            .affineTransform(inverseBoundingBoxTransform);
          if (centeredOriginPos.distance(boundingBoxHandlePosition) > MINIMUM_TOLERANCE) {
            boxOriginPos = centeredOriginPos;
          }
        }
        if (isUniform) {
          transformMatrix = AffineMatrix.fromCenterAndUniformScalePoints(
            boxOriginPos,
            boundingBoxHandlePosition,
            boxCurrentPosition
          );
        } else {
          transformMatrix = AffineMatrix.fromCenterAndNonUniformScalePoints(
            boxOriginPos,
            boundingBoxHandlePosition,
            boxCurrentPosition
          );
        }
      }

      transformMatrix.changeBasis(inverseBoundingBoxTransform, boundingBoxTransform); // Change basis from transform box to world coordinates
      transformMatrix.ensureMinimumBasisLength(globalState.project.settings.tolerance);

      const preserveRotation = selection.isSingle();
      transformer.transformWorld(transformMatrix, { preserveRotation });
    },
    onUp() {
      if (isOverrideTransformCenterSet) {
        globalState.project.clearOverrideTransformCenter();
      }
    },
  });
};

export const startSelectionTransformBoxRotateDrag = (
  downEvent: PointerEvent,
  selection: Selection,
  worldHandlePosition: Vec
) => {
  let transformer: SelectionTransformer;

  const isOverrideTransformCenterSet = trySetOverrideTransformCenter();
  const transformCenter = globalState.project.transformCenter();
  if (!transformCenter) return;

  const startAngle = worldHandlePosition.clone().sub(transformCenter.worldPosition).angle();

  startCanvasDrag(downEvent, {
    enableGridSnapping: false,
    disableDisplay: true,
    rotationTransformCenter: transformCenter.worldPosition,
    rotationReferenceStartAngle: startAngle,
    startWorldPosition: worldHandlePosition,
    onConsummate(event: PointerEvent, canvasDrag: CanvasDrag) {
      transformer = new SelectionTransformer(selection);
    },
    onMove(event: PointerEvent, canvasDrag: CanvasDrag) {
      const { currentPosition } = minimumPrecisionWorldPositionsFromCanvasDrag(canvasDrag);

      const rotationIncrement = rotationIncrementForCanvasDragEvent(canvasDrag, event);
      const transformMatrix = AffineMatrix.fromCenterAndReferencePoints(
        transformCenter.worldPosition,
        worldHandlePosition,
        currentPosition,
        {
          allowRotation: true,
          rotationIncrement,
        }
      );

      transformer.transformWorld(transformMatrix);
    },
    onUp() {
      if (isOverrideTransformCenterSet) {
        globalState.project.clearOverrideTransformCenter();
      }
    },
  });
};

const rotationIncrementForCanvasDragEvent = (canvasDrag: CanvasDrag, event: PointerEvent) => {
  if (canvasDrag.isPrecise()) return 0;
  if (event.shiftKey) return 15;
  return 1;
};
