import m from "mithril";

import { globalState } from "../global-state";
import { accountState } from "../shared/account";
import { ShareStatus } from "../shared/api-types";
import { AvatarImage } from "../shared/avatar-image";
import { canonicalOrigin } from "../shared/config";
import { ProFeatureButton, ProFeatureTag } from "../shared/feature-check";
import { Icon20, IconName } from "../shared/icon";
import { logEvent } from "../shared/log-event";
import { Tooltipped } from "../shared/popup";
import { canonicalUrlForForked, formatDate } from "../shared/util";
import { DocEditorTitle } from "./doc-editor/doc-editor-title";
import { enterEditingModeWithSaveConfirm } from "./save-confirm-modal";
import { showAdminFeatures } from "./util";
import { modalState } from "../shared/modal";
import { SimpleModal } from "./modal";
import { Checkbox } from "./basic/checkbox";

interface ProjectMetaAttrs {
  editable: boolean;
}
export const ProjectMeta: m.Component<ProjectMetaAttrs> = {
  view({ attrs: { editable } }) {
    const projectName = globalState.storage.getProjectName();
    const isReadOnly = !globalState.isEditingMode();

    const isProTemplate = globalState.storage.isProTemplate();
    const usesProFont = globalState.project.usesProFont();
    const enableProCheck = isProTemplate || usesProFont;
    const proFeatureKey = isProTemplate ? "templates" : "pro-fonts";

    const mEditViewLink = isReadOnly
      ? m(
          ProFeatureButton,
          {
            onClick: () => enterEditingModeWithSaveConfirm(),
            className: "secondary doc-editor-meta-edit-toggle",
            enableProCheck,
            feature: proFeatureKey,
          },
          [m(Icon20, { icon: "edit" }), "Open in Cuttle Editor"]
        )
      : m(
          "button.secondary.doc-editor-meta-edit-toggle",
          { onclick: () => globalState.enterViewingMode() },
          [m(Icon20, { icon: "visible" }), "Switch to Simplified View"]
        );

    return m(".doc-editor-meta", [
      m(DocEditorTitle, {
        value: projectName,
        editable,
        onchange: (newName: string) => {
          globalState.storage.setProjectName(newName);
        },
      }),
      m(".doc-editor-meta-top", [m(ProjectOwner), mEditViewLink]),
      m(ProjectTags),
      m(ProjectForked),
      m(ProjectStatus),
      m(ProjectLicense),
    ]);
  },
};

const ProjectTags: m.Component = {
  view() {
    const tags = globalState.storage.getProjectTags();

    const canEditTags =
      globalState.storage.isOfficialCuttleTemplate() &&
      globalState.storage.hasWritePermission() &&
      globalState.isEditingMode() &&
      showAdminFeatures();

    // Only show tags and edit UI with admin on Cuttle templates, for now.
    if (!canEditTags) return;

    let mEdit: m.Children;
    if (canEditTags) {
      const onclick = () => {
        modalState.open({ modalView: () => m(EditProjectTagsModal) });
      };
      mEdit = m("button.secondary", { onclick }, "Edit Tags");
    }

    let mTags: m.Children;
    if ((tags && tags.length > 0) || canEditTags) {
      const mTagLinks: m.Children[] = [];
      tags?.forEach((tag, i) => {
        mTagLinks.push(
          m("a.tag-link", { href: "/templates/" + encodeURIComponent(tag.slug) }, tag.tagName)
        );
        if (i < tags.length - 1) {
          mTagLinks.push(", ");
        }
      });
      mTags = m(
        ".doc-editor-project-tags",
        m(IconStatus, {
          icon: "tag",
          label: ["Tags", m(".feature-tag", "ADMIN"), mTagLinks, mEdit],
        })
      );
    }

    return mTags;
  },
};

const EditProjectTagsModal: m.ClosureComponent<{ onSave: () => void }> = () => {
  const tags = globalState.storage.getProjectTags();
  const allTags = globalState.storage.getAllTags();
  const selectedTags = new Set(tags?.map((t) => t.tagId));

  return {
    view() {
      let mContents: m.Children = "Tag list not loaded, please check back another time.";
      let primaryAction = { label: "OK", action: () => {} };

      if (allTags) {
        mContents = [];

        for (const tag of allTags) {
          const isSelected = selectedTags.has(tag.tagId);
          mContents.push(
            m("label", [
              m(Checkbox, {
                checked: isSelected,
                onclick: () => {
                  if (isSelected) {
                    selectedTags.delete(tag.tagId);
                  } else {
                    selectedTags.add(tag.tagId);
                  }
                },
              }),
              tag.tagName,
            ])
          );
        }

        primaryAction = {
          label: "Save",
          action: () => {
            const newTags = allTags.filter((tag) => selectedTags.has(tag.tagId));
            globalState.storage.setProjectTags(newTags);
            modalState.close();
          },
        };
      }

      return m(SimpleModal, { primaryAction }, m(".edit-project-tags-modal", mContents));
    },
  };
};

const ProjectOwner: m.Component = {
  view() {
    const username = globalState.storage.getProjectOwner();
    const displayName = globalState.storage.getProjectOwnerDisplayName();
    const avatarUrl = globalState.storage.getProjectOwnerAvatar();
    const openLinksInNewTab = globalState.isEditingMode();
    return m(
      ".doc-editor-by",
      m(UserLink, { username, displayName, openLinksInNewTab }, [
        m(AvatarImage, {
          avatarUrl,
          username,
          size: 32,
        }),
      ])
    );
  },
};

interface UserLinkAttrs {
  username: string;
  displayName?: string;
  openLinksInNewTab?: boolean;
}
export const UserLink: m.Component<UserLinkAttrs> = {
  view({ children, attrs: { username, displayName, openLinksInNewTab } }) {
    return m("a", { href: "/@" + username, target: openLinksInNewTab ? "_blank" : undefined }, [
      children,
      displayName || "@" + username,
    ]);
  },
};

interface ShareDisplayData {
  icon: IconName;
  label: string;
  tooltip: string;
}

const shareDisplayDataByStatus: Record<ShareStatus, ShareDisplayData> = {
  public: {
    icon: "share_public",
    label: "Public",
    tooltip: "Anyone can view this project and it will be listed on your public profile page",
  },
  unlisted: {
    icon: "share_unlisted",
    label: "Unlisted",
    tooltip: "Anyone with the link can view this project",
  },
  private: {
    icon: "share_private",
    label: "Private",
    tooltip: "Only you can view this project",
  },
};

const ProjectForked: m.Component = {
  view() {
    const forkedFrom = globalState.storage.getProjectForkedFrom();
    if (!forkedFrom) return;

    return m(".doc-editor-forked", [
      m(IconStatus, {
        icon: "fork",
        label: [
          "Remix of ",
          m(
            "a",
            {
              href: canonicalOrigin + canonicalUrlForForked(forkedFrom),
              target: "_blank",
            },
            forkedFrom.name
          ),
          " by ",
          m(UserLink, {
            username: forkedFrom.owner,
            displayName: forkedFrom.ownerDisplayName ?? undefined,
          }),
        ],
      }),
    ]);
  },
};

const ProjectStatus: m.Component = {
  view() {
    const publishedSnapshotId = globalState.storage.getPublishedSnapshotId();
    const currentSnapshotId = globalState.storage.getCurrentSnapshotId();
    const currentVersionPublished = publishedSnapshotId === currentSnapshotId;

    const hasWritePermission = globalState.storage.hasWritePermission();
    const shareStatus = globalState.storage.getShareStatus();
    const shareData = shareDisplayDataByStatus[shareStatus];

    const mStatuses: m.Child[] = [];
    mStatuses.push(m(IconStatus, shareData));

    const publishedDate = globalState.storage.getProjectPublishedDate();
    if (publishedDate) {
      let label = formatDate(publishedDate);
      const firstMadePublicDate = globalState.storage.getProjectFirstMadePublicDate();
      if (firstMadePublicDate) {
        const firstMadePublicString = formatDate(firstMadePublicDate);
        if (firstMadePublicString !== label) {
          label = `${firstMadePublicString} (updated ${label})`;
        }
      }
      mStatuses.push(
        m(IconStatus, {
          icon: "published",
          label,
        })
      );
    }
    if (shareStatus !== "private" && hasWritePermission && !currentVersionPublished) {
      mStatuses.push(
        m(IconStatus, {
          icon: "check",
          label: "Unpublished Changes",
          tooltip: "Changes have been made since the project was published",
          className: "is-behind",
        })
      );
    }

    if (mStatuses.length === 0) return;

    return m(".doc-editor-status", mStatuses);
  },
};

interface IconStatusAttrs {
  icon: IconName;
  label: m.Children;
  tooltip?: m.Children;
  className?: string;
}
const IconStatus: m.Component<IconStatusAttrs> = {
  view({ attrs: { icon, label, tooltip, className } }) {
    const mShareStatus = m(".icon-status", { className }, m(Icon20, { icon }), m(".label", label));
    if (tooltip) {
      return m(Tooltipped, { message: () => tooltip, placement: "top-start" }, mShareStatus);
    }
    return mShareStatus;
  },
};

export const ProjectLicense: m.Component = {
  view() {
    const isOfficialCuttleTemplate = globalState.storage.isOfficialCuttleTemplate();
    const isProTemplate = globalState.storage.isProTemplate();
    if (!isOfficialCuttleTemplate && !isProTemplate) return;

    const hasPro = accountState.featureFlags.hasProFeatures;

    let mLicenseDescription: m.Children;

    if (isProTemplate || isOfficialCuttleTemplate) {
      if (hasPro) {
        mLicenseDescription = [
          m(
            "p",
            "Your Cuttle Pro membership grants you a commercial license to sell physical items derived from this template."
          ),
        ];
      } else {
        if (isProTemplate) {
          mLicenseDescription = m("p", ["To make or sell physical items ", m(UpgradeLink), "."]);
        } else {
          mLicenseDescription = m("p", [
            "Free for personal use. To sell physical items ",
            m(UpgradeLink),
            ".",
          ]);
        }
      }

      return m(".doc-editor-license", [
        m(".license-header", [
          m(".license-title", "LICENSE"),
          m(Link, { href: "/learn/common-questions/licensing", openNewTab: true }, "Learn more"),
        ]),
        m(".license-description", mLicenseDescription),
        m(LicenseBullets, {
          bullets: [
            {
              type: isProTemplate ? "pro" : "allowed",
              text: "Create physical items for personal use.",
            },
            { type: "pro", text: "Sell physical items derived from this template." },
            {
              type: "restricted",
              text: [
                "Sell or give away digital items (like SVGs) derived from this template. We sometimes allow this on a case-by-case basis. ",
                m(
                  Link,
                  { href: "/learn/common-questions/licensing", openNewTab: true },
                  "Learn more"
                ),
                ".",
              ],
            },
          ],
        }),
      ]);
    }

    return; // Unofficial non-pro templates don't show a license.
  },
};

interface LinkAttrs {
  href: string;
  onclick?: (event: MouseEvent) => void;
  openNewTab?: boolean;
}
const Link: m.Component<LinkAttrs> = {
  view({ attrs: { href, onclick, openNewTab }, children }) {
    if (openNewTab) {
      return m("a[target=_blank]", { href, onclick }, children);
    }
    return m(m.route.Link, { href, onclick }, children);
  },
};

const UpgradeLink: m.Component = {
  view() {
    return m(
      Link,
      { href: "/pricing", onclick: () => logEvent("license upgrade"), openNewTab: true },
      "upgrade to Cuttle Pro"
    );
  },
};

type BulletType = "allowed" | "restricted" | "pro";
interface LicenseBulletAttrs {
  bullets: {
    type: BulletType;
    text: m.Children;
  }[];
}
const LicenseBullets: m.Component<LicenseBulletAttrs> = {
  view({ attrs: { bullets } }) {
    const iconForType = (type: BulletType) => {
      if (type === "allowed") {
        return m(Icon20, { icon: "license_allowed" });
      }
      if (type === "pro") {
        return m(ProFeatureTag);
      }
      return m(Icon20, { icon: "license_restricted" });
    };
    return m(
      ".license-bullets",
      bullets.map(({ type, text }) => {
        return [m(".license-bullet-icon", iconForType(type)), m(".license-bullet-text", text)];
      })
    );
  },
};
