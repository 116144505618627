import { AffineMatrix, BoundingBox, ClosestPointResult, DEFAULT_TOLERANCE, Graphic, Vec } from "..";

const tempHandleIn = new Vec();
const tempHandleOut = new Vec();

/**
 * An anchor is the editable unit of a path. Two anchors form a segment.
 */
export class Anchor extends Graphic {
  static readonly displayName = "Anchor";

  /**
   * The position of the anchor on the canvas.
   */
  position: Vec;

  /**
   * The tangent of the path before this anchor.
   *
   * Tangents are vectors relative to the anchor's `position`.
   */
  handleIn: Vec;

  /**
   * The tangent of the path after this anchor.
   *
   * Tangents are vectors relative to the anchor's `position`.
   */
  handleOut: Vec;

  /**
   * Constructs an anchor. Vectors passed to this constructor will be set as
   * their corresponding properties without any cloning. You may want to call
   * `.clone()` before passing them in.
   *
   * @param position
   * @param handleIn
   * @param handleOut
   */
  constructor(position = new Vec(), handleIn = new Vec(), handleOut = new Vec()) {
    super();
    this.position = position;
    this.handleIn = handleIn;
    this.handleOut = handleOut;
  }

  clone() {
    return new Anchor(this.position.clone(), this.handleIn.clone(), this.handleOut.clone());
  }

  isValid() {
    return Vec.isValid(this.position) && Vec.isValid(this.handleIn) && Vec.isValid(this.handleOut);
  }

  affineTransform(affineMatrix: AffineMatrix) {
    this.position.affineTransform(affineMatrix);
    this.handleIn.affineTransformWithoutTranslation(affineMatrix);
    this.handleOut.affineTransformWithoutTranslation(affineMatrix);
    return this;
  }

  affineTransformWithoutTranslation(affineMatrix: AffineMatrix) {
    this.position.affineTransformWithoutTranslation(affineMatrix);
    this.handleIn.affineTransformWithoutTranslation(affineMatrix);
    this.handleOut.affineTransformWithoutTranslation(affineMatrix);
    return this;
  }

  allAnchors() {
    return [this];
  }

  looseBoundingBox() {
    return new BoundingBox(this.position.clone(), this.position.clone());
  }

  boundingBox() {
    return new BoundingBox(this.position.clone(), this.position.clone());
  }

  isContainedByBoundingBox(box: BoundingBox) {
    return box.containsPoint(this.position);
  }

  isIntersectedByBoundingBox(box: BoundingBox) {
    const { min, max } = box;
    const { x, y } = this.position;
    return (
      (x >= min.x && x <= max.x && (y === min.y || y === max.y)) ||
      (y >= min.y && y <= max.y && (x === min.x || x === max.x))
    );
  }

  isOverlappedByBoundingBox(box: BoundingBox) {
    return box.containsPoint(this.position);
  }

  closestPoint(point: Vec, areaOfInterest?: BoundingBox): ClosestPointResult | undefined {
    const { position } = this;
    if (areaOfInterest && !areaOfInterest.containsPoint(position)) return;
    return { position };
  }

  reverse() {
    const { handleIn, handleOut } = this;
    this.handleIn = handleOut;
    this.handleOut = handleIn;
    return this;
  }

  hasTangentHandles(tolerance = DEFAULT_TOLERANCE) {
    tempHandleIn.copy(this.handleIn).normalize();
    tempHandleOut.copy(this.handleOut).normalize();
    return tempHandleIn.dot(tempHandleOut) <= tolerance - 1;
  }

  hasZeroHandles() {
    return this.handleIn.isZero() && this.handleOut.isZero();
  }

  static isValid(a: unknown): a is Anchor {
    return a instanceof Anchor && a.isValid();
  }
}
