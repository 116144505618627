import m from "mithril";

import { assert } from "../../geom";
import { CodeComponent } from "../../model/component";
import { Modifier } from "../../model/modifier";
import { SelectableInstance } from "../../model/selectable";
import { EvaluationResult } from "../evaluation-result";
import { ParameterInput } from "./parameter-input";

interface CodeInspectorAttrs {
  selectable: SelectableInstance;
  onClickCreateVariation: (event: MouseEvent) => void;
}
export const CodeInspector: m.Component<CodeInspectorAttrs> = {
  view({ attrs: { selectable, onClickCreateVariation } }) {
    const { instance } = selectable;
    const { definition } = instance;

    assert(definition instanceof CodeComponent || definition instanceof Modifier);

    const expression = definition.code;
    const expressionTrace = selectable.instanceTrace()?.code;

    const onChangeCode = (jsCode: string) => {
      expression.editingJsCode = jsCode;
    };
    const onCommitCode = () => {
      expression.commitEditingCode();
    };
    const onExpressionEditorBlur = () => {
      expression.commitEditingCode();
    };

    const isImmutableDefinition = definition.isImmutable;
    const isImmutable = selectable.isImmutable();

    return [
      m(
        ".inspector-row",
        m(".parameter-code-note", [
          isImmutableDefinition && !isImmutable
            ? [
                m(
                  "a",
                  {
                    href: "/learn/scripting",
                    target: "_blank",
                    onclick: onClickCreateVariation,
                  },
                  "Create a variation"
                ),
                ` to edit the code of this built-in ${
                  instance.definition instanceof CodeComponent ? "component" : "modifier"
                }. `,
              ]
            : [
                "See Cuttle's ",
                m("a", { href: "/learn/scripting", target: "_blank" }, "scripting reference"),
                ".",
              ],
        ])
      ),
      m(".inspector-row", [
        m(ParameterInput, {
          expression,
          expressionTrace,
          isImmutable,
          forceMultiline: true,
          selectable,
          onChangeCode,
          onCommitCode,
          onExpressionEditorBlur,
        }),
      ]),
      m(".inspector-row", m(EvaluationResult, { expressionTrace, units: undefined, selectable })),
    ];
  },
};
